import Joi from 'joi-browser';

export const inputSchemaForModuleConfig = Joi.object({}).required();
export const inputStructureSchema = Joi.object({
  type: Joi.string().required(),
  visible: Joi.string(),
  enabled: Joi.string(),
  default: Joi.any().required().allow('', null),
  required: Joi.boolean(),
  title: Joi.when('type', {
    is: Joi.string().valid('inputToModule', 'singleSelectDropDown'),
    then: Joi.string().required().allow(''),
    otherwise: Joi.any().forbidden(),
  }),
  values: Joi.when('type', {
    is: Joi.string().valid('toggle', 'singleSelectDropDown'),
    then: Joi.array().required(),
    otherwise: Joi.any().forbidden(),
  }),
  on: Joi.when('type', {
    is: Joi.string().valid('toggle'),
    then: Joi.any().required(),
    otherwise: Joi.any().forbidden(),
  }),
  off: Joi.when('type', {
    is: Joi.string().valid('toggle'),
    then: Joi.any().required(),
    otherwise: Joi.any().forbidden(),
  }),
  text: Joi.when('type', {
    is: Joi.string().valid('textbox', 'toggle'),
    then: Joi.string().required().allow(''),
    otherwise: Joi.when('type', {
      is: 'singleSelectDropDown',
      then: Joi.array().items(Joi.string()).required(),
      otherwise: Joi.any().forbidden(),
    }),
  }),
}).unknown(true);
export const inputSchemaForUIConfig = inputStructureSchema.keys({
  workflowKey: Joi.string().required(),
});

export const moduleConfigValidation = Joi.object({
  type: Joi.string().valid('superModule'),
  id: Joi.string().valid('<will_be_generated>'),
  subType: Joi.string().required(),
  initialStep: Joi.string().required(),
  deprecated: Joi.boolean(),
  // TODO: finalize a fixed structure for the objects inside builder properties
  builderProperties: Joi.object().pattern(
    Joi.string(),
    inputSchemaForModuleConfig,
  ).optional(),
  // TODO: finalize a fixed structure for the objects inside properties
  properties: Joi.object().pattern(
    Joi.string().regex(/^.+\[\+\].+$/),
    inputSchemaForModuleConfig,
  ).required(),
  library: Joi.object({
    conditionalVariables: Joi.object().pattern(
      Joi.string(),
      Joi.object({
        if_false: Joi.string().required().allow(''),
        if_true: Joi.string().required().allow(''),
        rule: Joi.string().required(),
        name: Joi.string().allow(''),
        parent: Joi.string().allow(''),
      }),
    ).required(),
    conditions: Joi.object().pattern(
      Joi.string(),
      Joi.object({
        if_false: Joi.string().required(),
        if_true: Joi.string().required(),
        rule: Joi.string().required(),
        name: Joi.string().allow(''),
        next_node_type: Joi.object({
          if_false: Joi.string(),
          if_true: Joi.string(),
        }),
      }),
    ).required(),
    modules: Joi.array().items(Joi.object({
      superModuleId: Joi.string().valid('').optional(),
      type: Joi.string().required(),
      subType: Joi.string().required(),
      id: Joi.string().required(),
      // empty strings are valid for dynamic forms
      nextStep: Joi.when('type', {
        is: Joi.string().valid('dynamicForm', 'dynamicFormV2'),
        then: Joi.string().allow('').required(),
        otherwise: Joi.string().required(),
      }),
      properties: Joi.object(),
    }).unknown(true))
      .required(),
    uiFormatting: Joi.object(),
    textFormatting: Joi.object(),
    properties: Joi.object(),
  }).required(),
  variables: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      path: Joi.string().required(),
      predefinedValues: Joi.array().items(Joi.object({
        label: Joi.string().required(),
        value: Joi.string().required(),
      })).optional(),
    }),
  ).allow([]).required(),
  version: Joi.string().optional(),
}).unknown(true);

export const moduleUiConfigValidation = Joi.object({
  moduleName: Joi.string().required(),
  description: Joi.string().required(),
  icon: Joi.string(),
  sections: Joi.object({
    inputs: Joi.array().items(inputSchemaForUIConfig).allow([]).required(),
    configurations: Joi.array().items(inputSchemaForUIConfig).allow([]).required(),
    outputs: Joi.array().items(
      Joi.object({
        type: Joi.string().valid('outputItem'),
        displayName: Joi.string().required(),
        description: Joi.string().required(),
        key: Joi.string().required(),
      }),
    ).allow([]).required(),
  }).required(),
});
