import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import HunterIcon from '../../assests/icons/hunterIcon.svg';
import { selectSelectedWorkflow, selectVersionedModules } from '../../reducers/workflow';
import { getWorkflowForModuleBuilderTesting, getLocalOrderOfNodes, updateModuleTestProperties } from '../../utils/moduleBuilderTestingUtils';
import compile from '../../compilerDecompiler/compiler';
import { generateModuleConfigsFromWorkflow, generateModuleUiConfigFromWorkflow } from '../../compilerDecompiler/workflowModuleTransformer';
import { selectCustomUIConfig, selectDefaultUIConfig } from '../../reducers/editBranding';
import { formComponentList } from '../../constants/dynamicFormComponents';
import useApiHooks from '../../hooks/api/useApiHooks';
import Modal from '../../components/Common/Modal';
import { compileUiConfig } from '../uiConfigOperations';
import ModuleConfigurationsEditor from '../PropertiesTab/ModuleConfigurationsEditor';

function TryModuleOnHunter({ workflowId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showModuleTestInputModal, setShowModuleTestInputModal] = useState(false);
  const [localWorkflow, setLocalWorkflow] = useState(null);
  const [localOrderOfModules, setLocalOrderOfModules] = useState([]);
  const [customUiConfigForTest, setCustomUiConfigForTesting] = useState(null);

  const { publishWorkflow, publishUiConfig } = useApiHooks();

  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const jwtToken = useSelector((state) => state.user.appIdKeyToken);

  const environment = process.env.REACT_APP_ENVIRONMENT;
  const hunterUrl = process.env.REACT_APP_HUNTER_URL;

  const compiledWorkflow = useMemo(
    () => compile(selectedWorkflow, versionedModules, formComponentList),
    [selectedWorkflow, versionedModules],
  );
  const moduleBuilderSubType = compiledWorkflow?.properties?.moduleBuilder?.subType;
  const moduleConfig = useMemo(() => generateModuleConfigsFromWorkflow(
    compiledWorkflow,
    customUiConfig,
  ), [
    compiledWorkflow,
    customUiConfig,
  ]);
  const moduleUiConfig = useMemo(
    () => generateModuleUiConfigFromWorkflow(compiledWorkflow),
    [compiledWorkflow],
  );
  const localVersionedModules = useMemo(() => ({
    ...versionedModules,
    [moduleBuilderSubType]: {
      v1: {
        config: moduleConfig,
        uiConfig: moduleUiConfig,
      },
    },
  }), [versionedModules, moduleBuilderSubType, moduleConfig, moduleUiConfig]);

  const nodeToBeTested = localOrderOfModules?.[2] ?? { id: '', nodeType: '', version: 'v1' };

  const tryOnHunter = (id) => {
    const url = new URL(`${hunterUrl}/session-manager/`);
    url.searchParams.append('workflowId', id);
    url.searchParams.append('appId', currentAppId);
    url.searchParams.append('authToken', jwtToken);
    if (environment === 'dev') {
      url.searchParams.append('env', environment);
    }
    setIsLoading(false);
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const isDisabled = useMemo(() => selectedWorkflow.modules.length === 1, [selectedWorkflow]);

  const onTryModule = async () => {
    try {
      // Get the basic compiled workflow using the moduleConfig and moduleUiConfig
      const {
        updatedWorkflow: workflowToTest,
        customUiConfig: customUiConfigWithSupermoduleUI,
      } = getWorkflowForModuleBuilderTesting(
        moduleBuilderSubType,
        localVersionedModules,
        moduleConfig,
        moduleUiConfig,
        defaultUiConfig,
      );
      setLocalWorkflow(workflowToTest);
      setCustomUiConfigForTesting(customUiConfigWithSupermoduleUI);

      const orderOfModules = getLocalOrderOfNodes(workflowToTest);
      setLocalOrderOfModules(orderOfModules);

      // Get the module inputs for testing
      setShowModuleTestInputModal(true);
    } catch (err) {
      alert(err.message);
    }
  };

  const onProceed = async () => {
    setIsLoading(true);
    try {
      const compiledWorkflowToTest = compile(
        localWorkflow,
        localVersionedModules,
        formComponentList,
      );
      // publish the workflow
      const moduleTestingWorkflowId = `${workflowId}_module_testing`;
      try {
        await publishWorkflow(currentAppId, moduleTestingWorkflowId, compiledWorkflowToTest);
        if (customUiConfigForTest && Object.keys(customUiConfigForTest).length > 0) {
          const compiledUiConfig = compileUiConfig(customUiConfigForTest, compiledWorkflowToTest);
          await publishUiConfig(moduleTestingWorkflowId, compiledUiConfig);
        }
      } catch (err) {
        alert(err.message);
      }
      tryOnHunter(moduleTestingWorkflowId);
    } catch (err) {
      alert(`Failed to try workflow: ${err.message}`);
      setIsLoading(false);
    }
  };

  const updateModulePropertyInWorkflow =
    useCallback((workflowKey, value, moduleId, currModuleConfig) => {
      const editedWorkflow = updateModuleTestProperties(
        workflowKey,
        value,
        moduleId,
        currModuleConfig,
        localWorkflow,
      );
      setLocalWorkflow(editedWorkflow);
    }, [localWorkflow]);

  return (
    <div>
      <Button className="try-workflow__option" disabled={isLoading || isDisabled} onClick={onTryModule}>
        <img className="try-workflow__option-icon" src={HunterIcon} alt="Hunter Icon" />
        <span className="try-workflow__option-text">Hunter</span>
        {
          isLoading
            ? <CircularProgress size="1rem" sx={{ color: 'rgba(5, 5, 82, 0.8)' }} />
            : null
        }
      </Button>
      <Modal
        isOpen={showModuleTestInputModal}
        onClose={() => {
          setShowModuleTestInputModal(false);
        }}
        onSave={onProceed}
        headerText="Module Test Input"
        buttonText="Proceed"
      >
        <ModuleConfigurationsEditor
          key={nodeToBeTested?.id || ''}
          moduleConfig={moduleConfig}
          inputs={moduleUiConfig?.sections?.inputs}
          configurations={moduleUiConfig?.sections?.configurations}
          selectedNodeId={nodeToBeTested?.id}
          selectedNodeType={nodeToBeTested?.nodeType}
          selectedNodeVersion={nodeToBeTested?.version}
          selectedWorkflow={localWorkflow}
          updateModulePropertyInWorkflow={updateModulePropertyInWorkflow}
          versionedModules={localVersionedModules}
          orderOfModules={localOrderOfModules}
          moduleBuilderTestMode
        />
      </Modal>
    </div>
  );
}

TryModuleOnHunter.propTypes = {
  workflowId: PropTypes.string.isRequired,
};

export default TryModuleOnHunter;
