import { cloneDeep } from 'lodash';

const processModuleBuilderProperties = (workflow) => {
  const editedWorkflow = cloneDeep(workflow);
  const { moduleBuilder = {} } = editedWorkflow?.properties || {};
  if (Object.keys(moduleBuilder).length) {
    const { moduleInputs = {}, builderProperties = {} } = moduleBuilder;
    const updatedModuleInputs = {};
    const updatedBuilderProperties = {};
    if (Object.keys(moduleInputs).length) {
      Object.entries(moduleInputs).forEach(([key, data]) => {
        updatedModuleInputs[key] = {
          ...data,
          config: {},
        };
      });
    }
    if (Object.keys(builderProperties).length) {
      Object.entries(builderProperties).forEach(([key, data]) => {
        updatedBuilderProperties[key] = {
          ...data,
          config: {},
        };
      });
    }
    editedWorkflow.properties.moduleBuilder = {
      ...moduleBuilder,
      moduleInputs: updatedModuleInputs,
      builderProperties: updatedBuilderProperties,
    };
  }
  return editedWorkflow;
};

export default processModuleBuilderProperties;
