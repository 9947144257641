import { cloneDeep, unset } from 'lodash';
import {
  fetchCurrentValueFromWorkflow,
  fetchCurrentValueFromWorkflowConfig,
  getSelectedModule,
  setModulePropertyInWorkflow,
} from '../../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import {
  addNodeFromHtmlString,
  copyComponentConfigs,
  copyNodeFromHtmlString,
  deleteNodeFromHtmlString,
  dragNodeFromHtmlString,
  getComponentConfigsWithRefreshedIds,
  getFormHtmlStringForV2,
  updateTagNameOfNodeInHtmlString,
} from '../../containers/FormModule/helper';

export const addFormComponentInV2 = (workflow, moduleId, componentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml } = addNodeFromHtmlString(componentId, htmlContent);
    const editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};

export const deleteFormComponentInV2 = (workflow, moduleId, componentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml, deletedNodeIds } = deleteNodeFromHtmlString(
      componentId,
      htmlContent,
    );
    const componentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const clonedComponentConfigs = cloneDeep(componentConfig);
    deletedNodeIds.forEach((id) => {
      unset(clonedComponentConfigs, id);
    });
    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'componentConfigs',
      clonedComponentConfigs,
      {},
    );
    editedWorkflow = setModulePropertyInWorkflow(
      editedWorkflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};

export const addFormComponentViaClipboardInFormV2 = (
  workflow,
  moduleId,
  htmlString,
  componentConfigsFromContextCopy,
) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml, map } = addNodeFromHtmlString(null, htmlContent, htmlString);
    const componentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const componentConfigForAddedNodes = getComponentConfigsWithRefreshedIds(
      componentConfigsFromContextCopy,
      map,
    );

    const updatedComponentConfigs = {
      ...cloneDeep(componentConfig || {}),
      ...(componentConfigForAddedNodes || {}),
    };

    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );

    editedWorkflow = setModulePropertyInWorkflow(
      editedWorkflow,
      moduleId,
      'componentConfigs',
      updatedComponentConfigs,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};

export const cloneFormComponentInV2 = (workflow, moduleId, componentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml, map } = copyNodeFromHtmlString(componentId, htmlContent);

    const componentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const updatedComponentConfig = copyComponentConfigs(componentConfig, map);

    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'componentConfigs',
      updatedComponentConfig,
      {},
    );
    editedWorkflow = setModulePropertyInWorkflow(
      editedWorkflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};

export const dragComponentInFormV2 = (workflow, moduleId, pickComponentId, dropComponentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const updatedHtml = dragNodeFromHtmlString(pickComponentId, dropComponentId, htmlContent);
    const editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};

export const updateTagNameOfComponentInFormV2 = (
  workflow,
  moduleId,
  componentId,
  tagName,
  libraryUrl,
  styleSheetsUrl,
  defaultAttributes,
  defaultProperties,
  defaultStyles,
  libraryId,
  formComponentId,
) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const updatedHtml = updateTagNameOfNodeInHtmlString(tagName, componentId, htmlContent);
    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    const existingLibraries = fetchCurrentValueFromWorkflowConfig(
      editedWorkflow,
      moduleId,
      'scripts',
    );
    const existingStyleSheets = fetchCurrentValueFromWorkflowConfig(
      editedWorkflow,
      moduleId,
      'stylesheets',
    );
    const existingComponentConfig = fetchCurrentValueFromWorkflowConfig(
      editedWorkflow,
      moduleId,
      `componentConfigs['${componentId}']`,
    );
    const {
      attributes: existingAttributes = {},
      properties: existingProperties = {},
      styles: existingStyles = {},
      ...rest
    } = existingComponentConfig || {};

    if (libraryId && formComponentId) {
      const attributes = {};
      Object.entries(defaultAttributes).forEach(([key, value]) => {
        attributes[key] =
          typeof existingAttributes[key] !== 'undefined' ? existingAttributes[key] : value;
      });

      const properties = {};
      Object.entries(defaultProperties).forEach(([key, value]) => {
        properties[key] =
          typeof existingProperties[key] !== 'undefined' ? existingProperties[key] : value;
      });

      const styles = {};
      Object.entries(defaultStyles).forEach(([key, value]) => {
        styles[key] = typeof existingStyles[key] !== 'undefined' ? existingStyles[key] : value;
      });

      const componentConfig = {
        ...rest,
        componentRef: {
          libraryId,
          formComponentId,
        },
        attributes,
        properties,
        styles,
      };

      editedWorkflow = setModulePropertyInWorkflow(
        editedWorkflow,
        moduleId,
        `componentConfigs['${componentId}']`,
        componentConfig,
      );
    }

    // TODO: This should be a post operation
    // library should be added if components of the same are used
    if (libraryUrl && !existingLibraries.includes(libraryUrl)) {
      editedWorkflow = setModulePropertyInWorkflow(editedWorkflow, moduleId, 'scripts', [
        ...existingLibraries,
        libraryUrl,
      ]);
    }

    if (styleSheetsUrl && !existingStyleSheets.includes(styleSheetsUrl)) {
      editedWorkflow = setModulePropertyInWorkflow(editedWorkflow, moduleId, 'stylesheets', [
        ...existingStyleSheets,
        styleSheetsUrl,
      ]);
    }
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};
