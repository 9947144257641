import { cloneDeep, set, unset } from 'lodash';
import { updateFormPropertyViaComponentId } from '../../components/FormModule/utils';
import {
  getSelectedModule,
  setModulePropertyInWorkflow,
  setSuperModulePatchProperty,
} from '../../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import {
  addComponent,
  copyComponent,
  deleteComponent,
  dragComponent,
  updateComponent,
} from '../../containers/FormModule/formOperations';
import {
  getFormComponents,
  getModuleFromId,
  updateOnReloadDependencyForModule,
} from '../../containers/FormModule/helper';
import { setUiConfigPropertyInHighLevelUiConfig } from '../../containers/uiConfigOperations';
import {
  addOperations,
  copyOperations,
  deleteOperations,
  dragOperations,
} from '../workflowOperation';

export const dragComponentWrapper = (
  workflow,
  moduleId,
  fromComponentId,
  toComponentId,
  rootPath,
) => {
  const { fromPathArray, toPathArray, updatedToPathArray } = dragOperations(
    workflow,
    moduleId,
    rootPath,
    fromComponentId,
    toComponentId,
  );

  if (fromPathArray.length > 0 && toPathArray.length > 0 && updatedToPathArray) {
    const { editedWorkflow, success } = dragComponent(
      workflow,
      moduleId,
      fromPathArray,
      updatedToPathArray,
      rootPath,
    );

    return { workflow: editedWorkflow, success };
  }

  return { workflow, success: false };
};

export const dragComponentInsideSuperModuleWrapper = (
  compiledWorkflow,
  moduleId,
  fromComponentId,
  toComponentId,
  rootPath,
  workflow,
) => {
  const { fromPathArray, toPathArray, updatedToPathArray } = dragOperations(
    compiledWorkflow,
    moduleId,
    rootPath,
    fromComponentId,
    toComponentId,
  );

  if (fromPathArray.length > 0 && toPathArray.length > 0 && updatedToPathArray) {
    // Call dragComponent to update the workflow
    const { editedWorkflow, success } = dragComponent(
      compiledWorkflow,
      moduleId,
      fromPathArray,
      updatedToPathArray,
      rootPath,
    );

    if (success) {
      const module = getModuleFromId(editedWorkflow, moduleId);

      const { mappingId, superModuleId } = module;
      const updatedComponents = getFormComponents(module, rootPath);
      const targetNodeId = superModuleId;
      const workflowKey = `${mappingId}[+]sections[0].${rootPath}`;
      const value = updatedComponents;
      const moduleConfig = { key: 'something' };

      const updatedWorkflow = setModulePropertyInWorkflow(
        workflow,
        targetNodeId,
        workflowKey,
        value,
        moduleConfig,
      );

      return { workflow: updatedWorkflow, success };
    }
  }

  return { workflow, success: false };
};

export const addComponentWrapper = (workflow, moduleId, defaultConfig, rootPath, componentId) => {
  const { toPathArray, indexToAddInPathArray } = addOperations(
    workflow,
    moduleId,
    rootPath,
    componentId,
  );
  const { editedWorkflow: operatedWorkflow, success } = addComponent(
    workflow,
    moduleId,
    defaultConfig,
    toPathArray,
    rootPath,
  );
  return {
    operatedWorkflow,
    success,
    toPathArray,
    indexToAddInPathArray,
  };
};

export const updateComponentWrapper = (
  workflow,
  moduleId,
  newComponent,
  pathArray,
  rootPath,
) => {
  const { editedWorkflow: operatedWorkflow, success } = updateComponent(
    workflow,
    moduleId,
    newComponent,
    pathArray,
    rootPath,
  );

  if (success) {
    const updatedModules = operatedWorkflow.modules.map((module) => {
      if (module.type === 'dynamicForm') {
        return updateOnReloadDependencyForModule(module);
      }
      return module;
    });

    operatedWorkflow.modules = updatedModules;
  }

  return { workflow: operatedWorkflow, success };
};

export const copyComponentWrapper = (
  workflow,
  moduleId,
  rootPath,
  componentId,
  formComponentList,
) => {
  const pathArray = copyOperations(workflow, moduleId, rootPath, componentId);
  const {
    workflow: operatedWorkflow,
    success,
    originalToClonedComponentIdMap,
  } = copyComponent(workflow, moduleId, pathArray, rootPath, formComponentList);

  if (success) {
    const updatedModules = operatedWorkflow.modules.map((module) => {
      if (module.type === 'dynamicForm') {
        return updateOnReloadDependencyForModule(module);
      }
      return module;
    });

    operatedWorkflow.modules = updatedModules;
  }

  return { workflow: operatedWorkflow, success, originalToClonedComponentIdMap };
};

export const deleteComponentWrapper = (
  workflow,
  moduleId,
  rootPath,
  componentId,
  selectedComponentPathArray,
  checkDepsFn,
) => {
  const {
    isSuccess, pathArray, newPathArray, componentToBeDeleted,
  } = deleteOperations(
    workflow,
    moduleId,
    rootPath,
    componentId,
    selectedComponentPathArray,
  );

  if (!isSuccess) {
    return { workflow, success: false };
  }

  const { workflow: operatedWorkflow, success } = deleteComponent(
    workflow,
    moduleId,
    pathArray,
    rootPath,
  );

  if (!success) {
    return { workflow, success: false };
  }

  const isDepsPresentInWorkflowAfterDeletion =
  checkDepsFn({
    variableId: componentId,
    nodeId: moduleId,
    workflow: operatedWorkflow,
  });

  if (isDepsPresentInWorkflowAfterDeletion) {
    return { workflow, success: false };
  }

  return {
    workflow: operatedWorkflow,
    success,
    extraData: {
      componentToBeDeleted,
      pathArray,
      newPathArray,
    },
  };
};

export const updateFormPropertyInSuperModule = ({
  workflow,
  uiConfig,
  superModuleId,
  mappingId,
  componentId,
  key,
  value,
  isUIProperty,
}) => {
  let editedWorkflow = cloneDeep(workflow);
  let editedUiConfig = cloneDeep(uiConfig);

  if (isUIProperty) {
    editedUiConfig = setUiConfigPropertyInHighLevelUiConfig({
      highLevelUiConfig: uiConfig,
      superModuleId,
      mappingId,
      componentId,
      key,
      value,
    });
    editedWorkflow.properties.uiConfigSource = 'custom';
  } else {
    const jsonPathQuery = `$..[?(@.id=='${componentId}')].${key}`;
    // LOGIC FOR SUPER MODULE EDITS HERE.
    editedWorkflow = setSuperModulePatchProperty(
      workflow,
      superModuleId,
      `${mappingId}[+]${jsonPathQuery}`,
      value,
    );
  }
  return { workflow: editedWorkflow, uiConfig: editedUiConfig, success: true };
};

export const updateFormProperty = ({
  workflow,
  uiConfig,
  key,
  value,
  moduleId,
  componentId,
  isUIProperty,
}) => {
  const editedWorkflow = cloneDeep(workflow);
  const editedUiConfig = cloneDeep(uiConfig);

  if (isUIProperty) {
    const path = `${moduleId}.${componentId}.${key}`;
    // TODO: Shouldn't it be null?
    if (value === '') unset(editedUiConfig, path);
    else set(editedUiConfig, path, value);
    editedWorkflow.properties.uiConfigSource = 'custom';
  } else {
    const selectedModule = getSelectedModule(editedWorkflow, moduleId);
    const editedModule = updateFormPropertyViaComponentId(selectedModule, componentId, key, value);
    const moduleIndex = editedWorkflow.modules.findIndex((module) => module.id === moduleId);
    editedWorkflow.modules[moduleIndex] = editedModule;
    const updatedModules = editedWorkflow.modules.map((module) => {
      if (module.type === 'dynamicForm') {
        return updateOnReloadDependencyForModule(module);
      }
      return module;
    });
    editedWorkflow.modules = updatedModules;
  }
  return { workflow: editedWorkflow, uiConfig: editedUiConfig, success: true };
};
