/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash';
import * as Sentry from '@sentry/react';
import removeUnvisitedNodesAndConditions from '../workflowOperations/utils';
import { prepareInputForWorkflowModificationFromCompiler, setModuleProperty, setModulePropertyViaJsonPath } from '../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import { replaceAll } from '../utils/helper';
import replaceNextStepId from './utils/replaceNextStepId';
import { getAllVariableIds } from '../components/ViewWorkflow/InputsToModule/utils';
import HVError, { errorCodes } from '../utils/error';

const replaceVariables = (workflow, replacements) => {
  const workflowString = JSON.stringify(workflow);
  let finalWorkflowString = workflowString;
  replacements.forEach((replacement) => {
    finalWorkflowString = replaceAll(finalWorkflowString, replacement.key, replacement.value);
  });
  const finalWorkflowObj = JSON.parse(finalWorkflowString);
  return finalWorkflowObj;
};

const isWorkflowVariable = (key, workflowVariables) => {
  if (typeof key !== 'string') return false;
  const { conditionalVariables, workflowInputs, moduleOutputs } = workflowVariables;
  return (
    conditionalVariables.includes(key) ||
    workflowInputs.includes(key) ||
    moduleOutputs.includes(key)
  );
};

const replaceValuesInRuleString = (ruleString, key, value, isWorkflowVar) => {
  const dataTypeOfValue = typeof value;
  if (['number', 'boolean', 'string'].includes(dataTypeOfValue)) {
    const updatedValue =
      dataTypeOfValue === 'string' && !isWorkflowVar
        ? `'${value}'`
        : value;
    const updatedRule = replaceAll(ruleString, key, `${updatedValue}`);
    return updatedRule;
  }
  return ruleString;
};

const replaceValuesInRules = (ruleObjects, key, value, isWorkflowVar) => {
  const updatedRules = cloneDeep(ruleObjects);
  Object.keys(ruleObjects).forEach((ruleId) => {
    const ruleObject = ruleObjects[ruleId];
    const updatedRule = replaceValuesInRuleString(ruleObject.rule, key, value, isWorkflowVar);
    ruleObject.rule = updatedRule;
    updatedRules[ruleId] = ruleObject;
  });
  return updatedRules;
};

const injectBuilderPropertiesInLibrary = (library, replacements, workflowVariables) => {
  // Resolved string values (non variables)
  // should be added with extra single quote while getting replaced in rules
  const clonnedLibrary = cloneDeep(library);
  replacements.forEach(({ key, value }) => {
    const isWorkflowVar = isWorkflowVariable(value, workflowVariables);
    clonnedLibrary.conditions = replaceValuesInRules(
      clonnedLibrary.conditions || {},
      key,
      value,
      isWorkflowVar,
    );
    clonnedLibrary.conditionalVariables = replaceValuesInRules(
      clonnedLibrary.conditionalVariables || {},
      key,
      value,
      isWorkflowVar,
    );
  });

  let libraryString = JSON.stringify(clonnedLibrary);
  replacements.forEach((replacement) => {
    const { key, value } = replacement;
    // boolean and number values should be added without quotes.
    if (typeof value === 'number' || typeof value === 'boolean') {
      libraryString = replaceAll(libraryString, `"${key}"`, `${value}`);
    } else if (typeof value === 'string') {
      libraryString = replaceAll(libraryString, `"${key}"`, `"${value}"`);
    }
    // Now we should be left with only rules which have builderProperties[-]v in them
    const isWorkflowVar = isWorkflowVariable(value, workflowVariables);
    libraryString = replaceValuesInRuleString(libraryString, key, value, isWorkflowVar);
  });
  return JSON.parse(libraryString);
};

const compileSuperModule = (superModule, versionedModuleConfigs, workflowVariables) => {
  // Use the library to create a template
  const superModuleId = superModule?.id;
  const superModuleType = superModule?.subType;
  const superModuleVersion = superModule?.version || 'v1';
  const moduleConfig = versionedModuleConfigs?.[superModuleType]?.[superModuleVersion]?.config;
  const { library, initialStep } = moduleConfig;
  const clonnedLibrary = cloneDeep(library);
  // replacing the builder properties with the value
  let replacementArrForBuilderProperties = [];
  if (Object.keys(superModule.builderProperties || {}).length) {
    clonnedLibrary.builderProperties = superModule.builderProperties;
    replacementArrForBuilderProperties = Object.entries(superModule.builderProperties || {}).reduce(
      (arrSoFar, [propName, propValue]) => [
        ...arrSoFar,
        {
          key: `builderProperties[-]${propName}`,
          value: propValue,
        },
      ],
      [],
    );
  }

  const definedBuilderProperties = Object.keys(superModule.builderProperties || {});
  Object.entries(moduleConfig.builderProperties || {}).forEach(([propName, propConfig]) => {
    const defaultValue = propConfig.default;
    if (!definedBuilderProperties.includes(propName) && typeof defaultValue !== 'undefined') {
      replacementArrForBuilderProperties.push({
        key: `builderProperties[-]${propName}`,
        value: defaultValue,
      });
    }
  });

  // Creating modules
  // create modulesMap
  const modulesMap = {};
  clonnedLibrary?.modules.forEach((module) => {
    modulesMap[module.id] = module;
  });

  // Distribute properties
  const propertiesArr = Object.keys(superModule.properties);

  // TODO : Make this rule strict to identify precompiled properties,
  // can consider type from ui config,
  const nonPrecompiledProperties =
    propertiesArr.filter((property) => !property.includes('[+]sections[0]'));

  nonPrecompiledProperties.forEach((property) => {
    const [mappingId, actualProperty] = property.split('[+]');
    if (!mappingId || !actualProperty) {
      const errorMessage = `${property} is not valid`;
      Sentry.captureException(new Error(errorMessage));
      throw new HVError({
        code: errorCodes.inValidProperty,
        message: errorMessage,
        originalError: new Error(errorMessage),
      });
    }
    const inputValue = prepareInputForWorkflowModificationFromCompiler(
      superModule.properties[property],
      moduleConfig.properties[property],
      property,
    );
    modulesMap[mappingId].properties = setModuleProperty(
      actualProperty,
      inputValue,
      modulesMap[mappingId].properties,
    );
  });

  const patchedPropertiesKeys = Object.keys(superModule.patchedProperties || {});
  patchedPropertiesKeys.forEach((property) => {
    const [mappingId, queryPath] = property.split('[+]');
    if (!mappingId || !queryPath) {
      const errorMessage = `${property} is not valid`;
      throw new HVError({
        code: errorCodes.inValidProperty,
        message: errorMessage,
        originalError: new Error(errorMessage),
      });
    }
    modulesMap[mappingId].properties = setModulePropertyViaJsonPath(
      queryPath,
      superModule.patchedProperties[property],
      modulesMap[mappingId].properties,
    );
  });

  // Updation of internal module ids
  // uuid -> some 5 digit alfa numeric id
  // module_aadhaar -> module_uuid_<subType of the individual module>
  // JSON.stringify and replace
  const replacementArrForModuleIds = [];
  const mappingIdMap = {}; // acutalModuleId: mappingId
  const mappingIdtoActualIdMap = {};
  clonnedLibrary.modules.forEach((module) => {
    const finalModuleId = `${superModuleId}_${module.id}_${module.subType}`;
    replacementArrForModuleIds.push({
      key: module.id,
      value: finalModuleId,
    });
    mappingIdMap[finalModuleId] = module.id;
    mappingIdtoActualIdMap[module.id] = finalModuleId;
  });
  const replacementArrForConditionalVariables = [];
  const conditionalVariablesArr = Object.keys(clonnedLibrary.conditionalVariables);
  conditionalVariablesArr.forEach((conditionalVariable) => {
    replacementArrForConditionalVariables.push({
      key: conditionalVariable,
      value: `${superModuleId}_${conditionalVariable}`,
    });
  });
  const repalcementForConditionIds = [];
  const conditionaIdsArr = Object.keys(clonnedLibrary.conditions);
  conditionaIdsArr.forEach((conditionalId) => {
    const finalConditionId = `condition_${superModuleId}_${conditionalId}`;
    repalcementForConditionIds.push({
      key: conditionalId,
      value: finalConditionId,
    });
    mappingIdMap[finalConditionId] = conditionalId;
    mappingIdtoActualIdMap[conditionalId] = finalConditionId;
  });
  const replacementArr = [
    ...replacementArrForModuleIds,
    ...repalcementForConditionIds,
    ...replacementArrForConditionalVariables,
    {
      key: 'EXIT_POINT',
      value: `${superModuleId}_EXIT_POINT`,
    },
  ];
  const injectedLibraryWithBuilderProperties = injectBuilderPropertiesInLibrary(
    clonnedLibrary,
    replacementArrForBuilderProperties,
    workflowVariables,
  );
  const compiledModule = replaceVariables(injectedLibraryWithBuilderProperties, replacementArr);
  const compiledModulesMap = {};
  compiledModule.modules.forEach((module) => {
    module.mappingId = mappingIdMap[module.id];
    // eslint-disable-next-line no-param-reassign
    module.superModuleType = superModuleType;
    module.superModuleId = superModuleId;
    compiledModulesMap[module.mappingId] = module;
  });

  const variableReplacementsList = [];
  try {
    moduleConfig.variables.forEach((variable) => {
      const { name, path } = variable;
      const [mappingModuleId, ...keyNames] = path.split('.');
      const key = keyNames.join('.');
      if (mappingModuleId === 'conditionalVariables') {
        variableReplacementsList.push({
          key: `${superModuleId}.${name}`,
          value: `conditionalVariables.${superModuleId}_${key}`,
        });
      } else {
        variableReplacementsList.push({
          key: `${superModuleId}.${name}`,
          value: `${mappingIdtoActualIdMap[mappingModuleId]}.${key}`,
        });
      }
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }

  const mappingIdOfTheFirstStep = initialStep || library.modules[0].id;
  const startNodeOfTheLibrary = mappingIdtoActualIdMap[mappingIdOfTheFirstStep];
  const exitPointReplacement = {
    key: `${superModuleId}_EXIT_POINT`,
    value: superModule.nextStep,
  };
  return {
    compiledModule,
    variableReplacementsList,
    startNodeOfTheLibrary,
    exitPointReplacement,
    superModuleVersion,
  };
};

const compile = (highLevelWorkflow, versionedModuleConfigs, formComponentList) => {
  const workflowVariables = getAllVariableIds(
    highLevelWorkflow,
    formComponentList,
    versionedModuleConfigs,
  );

  let lowLevelWorkflow = {};
  const { modules: originalModules, ...rest } = highLevelWorkflow;
  lowLevelWorkflow = { ...rest };
  const modules = [];
  const superModuleMap = {};
  let metaData = {};
  originalModules.forEach((module) => {
    if (module?.type === 'superModule') {
      // complex super module
      const { id: superModuleId } = module;
      superModuleMap[superModuleId] = module;
    } else {
      // simple module
      modules.push({ ...module, version: module.version || 'v1' });
    }
  });
  let replacements = [];
  const compiledModules = [];
  const firstStepReplacementArr = [];
  const firstStepReplacementMap = {};
  const exitPointReplacements = [];
  Object.keys(superModuleMap).forEach((superModuleId) => {
    const {
      compiledModule,
      variableReplacementsList,
      startNodeOfTheLibrary,
      exitPointReplacement,
      superModuleVersion,
    } = compileSuperModule(
      superModuleMap[superModuleId],
      versionedModuleConfigs,
      workflowVariables,
    );
    metaData = {
      ...metaData,
      [superModuleId]: {
        startNodeId: startNodeOfTheLibrary,
        exitNodeId: exitPointReplacement?.value,
        moduleName: superModuleMap[superModuleId]?.name || null,
        nextNodeType: superModuleMap[superModuleId]?.next_node_type || {},
        version: superModuleVersion,
        patchedProperties: superModuleMap[superModuleId].patchedProperties || {},
      },
    };
    replacements = [...replacements, ...variableReplacementsList];
    compiledModules.push(compiledModule);
    firstStepReplacementArr.push({
      key: superModuleId,
      value: startNodeOfTheLibrary,
    });
    firstStepReplacementMap[superModuleId] = startNodeOfTheLibrary;
    exitPointReplacements.push(exitPointReplacement);
    // TODO: Why are we doing this ?
    lowLevelWorkflow.properties = {
      ...lowLevelWorkflow.properties,
    };

    if (compiledModule.builderProperties) {
      const newBuilderProperties = {
        ...(lowLevelWorkflow?.properties?.builderProperties || {}),
        [superModuleId]: compiledModule.builderProperties,
      };
      lowLevelWorkflow.properties.builderProperties = newBuilderProperties;
    }
  });
  Object.keys(metaData).forEach((superModuleId) => {
    const { exitNodeId } = metaData[superModuleId];
    if (metaData[exitNodeId]) {
      // its pointing to a super module id, should be converted to the actual id
      const actualId = metaData[exitNodeId].startNodeId;
      metaData[superModuleId].exitNodeId = actualId;
    }
  });
  lowLevelWorkflow.properties = {
    ...lowLevelWorkflow.properties,
    builder: {
      superModuleMetaData: metaData,
    },
    builtOnBuilder: true,
  };
  lowLevelWorkflow.modules = modules;
  compiledModules.forEach((compiledModule) => {
    lowLevelWorkflow.modules = [...lowLevelWorkflow.modules, ...compiledModule.modules];
    lowLevelWorkflow.conditions = {
      ...lowLevelWorkflow.conditions,
      ...compiledModule.conditions,
    };
    lowLevelWorkflow.conditionalVariables = {
      ...lowLevelWorkflow.conditionalVariables,
      ...compiledModule.conditionalVariables,
    };
  });
  lowLevelWorkflow = replaceVariables(lowLevelWorkflow, replacements);
  // Update the nextSteps of the parent nodes
  lowLevelWorkflow = replaceNextStepId(lowLevelWorkflow, firstStepReplacementArr);

  // Updated EXIT POINT
  const exitPointsReplacement = exitPointReplacements.map((exitPoint) => {
    const { key, value } = exitPoint;
    if (superModuleMap[value]) {
      // super module id
      return {
        key,
        value: firstStepReplacementMap[value],
      };
    }
    // simple module
    return { key, value };
  });

  lowLevelWorkflow = replaceVariables(lowLevelWorkflow, exitPointsReplacement);
  lowLevelWorkflow = removeUnvisitedNodesAndConditions(lowLevelWorkflow);
  return lowLevelWorkflow;
};

export default compile;
