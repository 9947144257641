import { endStates, internalEndStateKeys } from '../components/constants';
import MODULE_COUNTRY_PICKER from '../constants/miscellaneous';

const generateBaseStructureForModule = (subType) => ({
  type: 'superModule',
  id: '<will_be_generated>',
  subType,
  nextStep: '',
  initialStep: '',
  deprecated: false,
  builderProperties: {},
  properties: {},
  variables: [],
  library: {
    properties: {},
    modules: [],
    conditions: {},
    conditionalVariables: {},
  },
});

const generateBaseStructureForUiConfig = (moduleName, description, moduleIcon) => ({
  moduleName,
  description,
  ...(moduleIcon ? { icon: moduleIcon } : {}),
  sections: {
    inputs: [],
    configurations: [],
    outputs: [],

  },
});

const transformRawInputsToProperties = (inputs) => Object.values(inputs).filter((input) => input.configSectionName === 'properties').reduce((agg, input) => {
  const { workflowKey = '', config = {} } = input;
  // eslint-disable-next-line no-param-reassign
  agg[workflowKey] = config;
  return agg;
}, {});

const transformRawBuilderPropertiesToModuleConfig = (builderProperties) => (
  Object.values(builderProperties)
    .filter((property) => property.configSectionName === 'builderProperties').reduce((agg, input) => {
      const { workflowKey = '', config = {} } = input;
      const configKey = workflowKey.startsWith('builderProperties[-]') ? workflowKey.replace('builderProperties[-]', '') : workflowKey;
      // eslint-disable-next-line no-param-reassign
      agg[configKey] = config;
      return agg;
    }, {}));

const transformRawOutputToVariables = (outputs) => Object.values(outputs).map((output) => ({
  name: output.name,
  path: output.path,
}));

const generateInputsForModuleProperties = ({ workflowKey = '', uiConfig = {} }) => ({
  workflowKey,
  ...uiConfig,
});

const generateInputsForUiConfig = (inputs) => inputs
  .map((input) => generateInputsForModuleProperties(input));

const transformOutputsForUiConfig = (outputs) => Object.values(outputs).map((output) => ({
  type: output.type,
  displayName: output.displayName,
  description: output.description,
  key: output.name,
}));

const generateModuleIdAndPathFromWorkflowKey = (key) => {
  const [moduleId, ...pathArray] = key.split('.');
  const path = pathArray.join('.');
  return {
    moduleId,
    path,
  };
};

export const preProcessWorkflow = (workflow, endStatesList, internalEndStateKeyList) => {
  const workflowStringified = JSON.stringify(workflow);
  const { properties } = workflow;
  const moduleBuilderProperties = properties?.moduleBuilder || {};
  const {
    moduleInputs = {},
    builderProperties = {},
  } = moduleBuilderProperties;
  const moduleInputMapping = Object.values(moduleInputs).map((input) => {
    const { moduleId, path } = generateModuleIdAndPathFromWorkflowKey(input.workflowKey);
    return {
      searchString: input.workflowKey,
      replaceString: `${moduleId}[+]${path}`,
    };
  });

  const builderPropertiesMapping = Object.values(builderProperties).map((property) => {
    const { moduleId, path } = generateModuleIdAndPathFromWorkflowKey(property.workflowKey);
    return {
      searchString: property.workflowKey,
      replaceString: `${moduleId}[-]${path}`,
    };
  });

  const replacePropertyMapping = [
    ...moduleInputMapping,
    ...builderPropertiesMapping,
  ];

  const workflowWithPropertiesReplaced = replacePropertyMapping
    .reduce((currWorkflow, replaceObj) => {
      const { searchString = '', replaceString = '' } = replaceObj;
      return currWorkflow.replaceAll(searchString, replaceString);
    }, workflowStringified);

  const workflowWithExitNodes = Object.keys(endStatesList)
    .filter((key) => !internalEndStateKeyList.includes(key))
    .reduce((intermediateString, keyToReplace) => intermediateString.replaceAll(keyToReplace, 'EXIT_POINT'), workflowWithPropertiesReplaced);

  const finalProcessedWorkflow = JSON.parse(workflowWithExitNodes);

  return finalProcessedWorkflow;
};

export const generateModuleConfigsFromWorkflow = (
  workflow,
  customUiConfig,
  customTextConfig,
) => {
  const finalProcessedWorkflow = preProcessWorkflow(workflow, endStates, internalEndStateKeys);
  const {
    modules,
    conditionalVariables,
    conditions,
    properties,
  } = finalProcessedWorkflow;
  const moduleBuilderObject = properties?.moduleBuilder || {};
  const {
    subType = '',
    moduleInputs = {},
    output = {},
    builderProperties = {},
  } = moduleBuilderObject;

  const baseModuleConfig = generateBaseStructureForModule(subType);

  const modulesWithoutCountryPicker = modules.filter(
    (module) => module.id !== MODULE_COUNTRY_PICKER,
  );
  const countryPickerModule = modules.find((module) => module.id === MODULE_COUNTRY_PICKER);

  const processedModulesForSupermodule = modulesWithoutCountryPicker.map((module) => {
    const { version, ...moduleWithoutVersion } = module;
    return ({
      ...moduleWithoutVersion,
      superModuleType: subType,
      superModuleId: '',
    });
  });

  baseModuleConfig.initialStep = countryPickerModule.nextStep;
  baseModuleConfig.library.modules = processedModulesForSupermodule;
  baseModuleConfig.library.conditionalVariables = conditionalVariables;
  baseModuleConfig.library.conditions = conditions;
  baseModuleConfig
    .builderProperties = transformRawBuilderPropertiesToModuleConfig(builderProperties);
  baseModuleConfig.properties = transformRawInputsToProperties(moduleInputs);
  baseModuleConfig.variables = transformRawOutputToVariables(output);
  if (Object.keys(customUiConfig || {})?.length) {
    const moduleIds = (baseModuleConfig.library.modules || []).map(({ id }) => id);
    baseModuleConfig.library.uiFormatting = moduleIds.reduce((acc, curr) => (customUiConfig[curr] ?
      { [curr]: customUiConfig[curr], ...acc } : acc), {});
  }
  if (Object.keys(customTextConfig || {})?.length) {
    const moduleIds =
    (baseModuleConfig.library.modules
      || []).map(({ id, type: moduleType, subType: moduleSubType }) => ({
      id,
      moduleType,
      moduleSubType,
    }));
    baseModuleConfig.library.textFormatting = moduleIds.reduce((acc, curr) => {
      const keyToSet = curr.moduleType === 'face' ? curr.moduleSubType : curr.id;
      return customTextConfig[`${keyToSet}_${curr.moduleSubType}`]
        ? { [keyToSet]: customTextConfig[`${keyToSet}_${curr.moduleSubType}`], ...acc }
        : acc;
    }, {});
  }
  return baseModuleConfig;
};

const segregateInputsInConfigurationsAndInputs = (moduleProperties, builderProperties) => {
  const inputsFromModuleProperties = Object.values(moduleProperties).filter((input) => input.uiConfigSectionName === 'inputs');
  const configurationsFromModuleProperties = Object.values(moduleProperties).filter((input) => input.uiConfigSectionName === 'configurations');
  const inputsFromBuilderProperties = Object.values(builderProperties).filter((property) => property.uiConfigSectionName === 'inputs');
  const configurationsFromBuilderProperties = Object.values(builderProperties).filter((property) => property.uiConfigSectionName === 'configurations');
  const inputs = [...inputsFromModuleProperties, ...inputsFromBuilderProperties];
  const configurations = [
    ...configurationsFromModuleProperties,
    ...configurationsFromBuilderProperties,
  ];
  return { inputs, configurations };
};

export const generateModuleUiConfigFromWorkflow =
  (workflow) => {
    const finalProcessedWorkflow = preProcessWorkflow(workflow, endStates, internalEndStateKeys);
    const moduleBuilderObject = finalProcessedWorkflow?.properties?.moduleBuilder || {};
    const {
      moduleInputs = {},
      output = {},
      builderProperties = {},
    } = moduleBuilderObject;
    const baseUiConfig = generateBaseStructureForUiConfig(
      finalProcessedWorkflow?.properties?.name,
      finalProcessedWorkflow?.properties?.description,
      finalProcessedWorkflow?.properties?.moduleBuilder?.moduleIcon,
    );
    const { inputs, configurations } = segregateInputsInConfigurationsAndInputs(
      moduleInputs,
      builderProperties,
    );
    baseUiConfig.sections.inputs = generateInputsForUiConfig(inputs);
    baseUiConfig.sections.configurations = generateInputsForUiConfig(configurations);
    baseUiConfig.sections.outputs = transformOutputsForUiConfig(output);
    return baseUiConfig;
  };
