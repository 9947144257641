import TYPE_TO_SUBTYPE_MAP from '../pages/constants';
import { checkAndUpdateNextStepsInFormModuleV2, checkAndUpdateNextStepsInFormModule } from '../compilerDecompiler/utils/replaceNextStepId';
import updateModuleSubtypes from './updateModuleSubtype';

export const validateAndUpdateModulesSubtypes = (
  workflow,
  versionedModules,
) => {
  const updatedModules = updateModuleSubtypes(
    workflow.modules,
    versionedModules,
  );
  const subtypeErrorItems = updatedModules
    .map((module) => {
      const originalModule = workflow.modules.find((orig) => orig.id === module.id);
      return {
        module,
        originalModule, // Keep reference to the original module
      };
    })
    .filter(({ module, originalModule }) => module.subType !== originalModule?.subType)
    .map(({ module, originalModule }) => ({
      description: `- ${originalModule?.subType}`, // Use original subType for the description
      actionText: `Changing subType to ${TYPE_TO_SUBTYPE_MAP[module.type].subType}`, // Updated subType
    }));
  const updatedWorkflow = {
    ...workflow,
    modules: updatedModules,
  };
  const errorData = [];
  if (subtypeErrorItems.length > 0) {
    errorData.push({
      message: 'The following module subTypes are not builder-compatible. You can change the subTypes to "genericApiV1" in case of an API, or "genericWebviewV1" in case of a Webview. This will allow you to import the workflow',
      items: subtypeErrorItems,
    });
  }
  return { workflow: updatedWorkflow, errorData };
};

export const changeNextStepToApprove = (module, deletedSteps) => {
  // Create a replacement map where each deleted step maps to 'approve'
  const replacementMap = deletedSteps.reduce((map, step) => ({
    ...map,
    [step]: 'approve',
  }), {});

  if (module.type === 'dynamicForm') {
    const updatedModule = checkAndUpdateNextStepsInFormModule(module, replacementMap);
    return updatedModule;
  } if (module.type === 'dynamicFormV2') {
    const updatedModule = checkAndUpdateNextStepsInFormModuleV2(module, replacementMap);
    return updatedModule;
  } if (module.type !== 'countries' && replacementMap[module.nextStep]) {
    const updatedModule = { ...module, nextStep: 'approve' };
    return updatedModule;
  }

  return module;
};

export const checkDeletedModulesOrConditions = (workflow, deletedSteps) => {
  const errorData = [];
  if (deletedSteps && deletedSteps.length > 0) {
    errorData.push({
      message: 'The following nextSteps are present in the workflow but the module/condition does not exist. You can change the nextStep to approve which will let you import the workflow',
      items: deletedSteps.map((step) => ({
        description: `- ${step}`,
        actionLink: '#',
        actionText: 'Change nextStep to "approve"',
      })),
    });
    const updatedModules = workflow.modules.map(
      (module) => changeNextStepToApprove(module, deletedSteps),
    );

    const updatedWorkflow = {
      ...workflow,
      modules: updatedModules,
    };

    return { workflow: updatedWorkflow, errorData };
  }
  return { workflow, errorData };
};

// TODO: Need to have seperation of concerns for the validation and the corrections process
const preWorkflowOperations = (
  workflow,
  versionedModules,
  deletedSteps = [],
) => {
  const operations = [
    (currentWorkflow) => validateAndUpdateModulesSubtypes(
      currentWorkflow,
      versionedModules,
    ),
    (currentWorkflow) => checkDeletedModulesOrConditions(currentWorkflow, deletedSteps),
  ];

  const { workflow: updatedWorkflow, errorData: finalErrorData } = operations.reduce(
    (acc, operation) => {
      // Run the operation and return the updated workflow and errorData
      const result = operation(acc.workflow, acc.errorData);
      return { workflow: result.workflow, errorData: acc.errorData.concat(result.errorData) };
    },
    { workflow, errorData: [] },
  );

  return { workflow: updatedWorkflow, errorData: finalErrorData };
};

export default preWorkflowOperations;
