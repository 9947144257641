import Joi from 'joi-browser';
import { cloneDeep, set } from 'lodash';
import {
  setModulePropertyInWorkflow,
  setPreviousStepInWorkflow,
  unsetModulePropertyInWorkflow,
  unsetPreviousStepInWorkflow,
  updateCountriesInWorkflow,
  updateDocumentsInWorkflow,
} from '../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import {
  setValueInWorkflow,
  unSetValueInWorkflow,
} from '../components/ViewWorkflow/ConfigurationPanel/helper';
import {
  addNewNodeInWorkflow,
  deleteNodeFromWorkflow,
  updateNodeName,
} from './operations/genericOperations';
import {
  editIfTrueIfFalseReason,
  swapBranchesInCondition,
  updateConditionRule,
  editResumeFrom,
} from './operations/conditionOperations';
import {
  addFormComponentInV2,
  addFormComponentViaClipboardInFormV2,
  cloneFormComponentInV2,
  deleteFormComponentInV2,
  dragComponentInFormV2,
  updateTagNameOfComponentInFormV2,
} from './operations/formV2Operations';
import {
  addComponentWrapper,
  copyComponentWrapper,
  deleteComponentWrapper,
  dragComponentInsideSuperModuleWrapper,
  dragComponentWrapper,
  updateComponentWrapper,
  updateFormProperty,
  updateFormPropertyInSuperModule,
} from './operations/formOperations';
import { pasteInDynamicForm } from '../containers/FormModule/formOperations';
import setModuleBuilderConfigurations from './operations/moduleBuilderOperations';
import { inputStructureSchema, inputSchemaForModuleConfig } from '../schemas/moduleBuilderSchemas';

const workflowOperations = {
  DELETE_CONDITION: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      // TODO: Can this parentId be removed?
      parentId: Joi.string().required(),
    }),
    // Controller
    operatingFn: (workflow, actionData) => {
      const { targetNodeId, parentId } = actionData;
      const { workflow: operatedWorkflow, success } =
        deleteNodeFromWorkflow(targetNodeId, parentId, workflow);
      if (success) return { workflow: operatedWorkflow, success };
      return { workflow, success: false };
    },
  },
  DELETE_MODULE: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      // TODO: Can this parentId be removed?
      parentId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { targetNodeId, parentId } = actionData;
      const { workflow: operatedWorkflow, success } =
        deleteNodeFromWorkflow(targetNodeId, parentId, workflow);
      if (success) return { workflow: operatedWorkflow, success };
      return { workflow, success: false };
    },
  },
  ADD_CONDITION: {
    actionDataSchema: Joi.object({
      addNodeBetween: Joi.object({
        parent: Joi.string().required(),
        child: Joi.string().required(),
      }),
      // TODO: Remove dependency on the below two properties.
      nodes: Joi.array(),
      localOrderOfNodes: Joi.array(),
    }),
    operatingFn: (workflow, actionData) => {
      const { addNodeBetween, nodes, localOrderOfNodes } = actionData;
      const { workflow: operatedWorkflow, newNodeId, success } =
        addNewNodeInWorkflow({
          addNodeBetween, nodes, workflowConfig: workflow, localOrderOfNodes, type: 'condition',
        });
      if (success) return { workflow: operatedWorkflow, extraData: { newNodeId }, success };
      return { workflow, success: false };
    },
  },
  ADD_MODULE: {
    actionDataSchema: Joi.object({
      node: Joi.object(),
      addNodeBetween: Joi.object({
        parent: Joi.string().required(),
        child: Joi.string().required(),
      }),
      countryDocMapping: Joi.object(),
      defaultFormSections: Joi.array(),
      // TODO: Remove dependency on the below two properties.
      nodes: Joi.array(),
      localOrderOfNodes: Joi.array(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        node, countryDocMapping,
        defaultFormSections, addNodeBetween,
        nodes, localOrderOfNodes,
      } = actionData;
      const {
        workflow: operatedWorkflow, highLevelUiConfig, newModule, success, highLevelTextConfig,
      } =
        addNewNodeInWorkflow({
          node,
          addNodeBetween,
          nodes,
          workflowConfig: workflow,
          countryDocMapping,
          localOrderOfNodes,
          defaultFormSections,
          type: 'module',
        });
      if (success) {
        return {
          workflow: operatedWorkflow,
          extraData: { highLevelUiConfig, newModule, highLevelTextConfig },
          success,
        };
      }
      return { workflow, success: false };
    },
  },
  SET_WORKFLOW_ATTRIBUTE: {
    actionDataSchema: Joi.object({
      path: Joi.string().required(),
      value: Joi.any().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        path, value,
      } = actionData;
      const clonedWorkflow = cloneDeep(workflow);
      set(clonedWorkflow, path, value);
      return { workflow: clonedWorkflow, success: true };
    },
  },
  SET_MODULE_PROPERTY: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      workflowKey: Joi.string().required(),
      value: Joi.any().required(),
      moduleConfig: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
        workflowKey,
        value,
        moduleConfig,
      } = actionData;
      const updatedWorkflow = setModulePropertyInWorkflow(
        workflow,
        targetNodeId,
        workflowKey,
        value,
        moduleConfig,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  UNSET_MODULE_PROPERTY: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      workflowKey: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
        workflowKey,
      } = actionData;
      const updatedWorkflow = unsetModulePropertyInWorkflow(
        workflow,
        targetNodeId,
        workflowKey,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SWAP_CONDITION_BRANCHES: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
      } = actionData;
      const updatedWorkflow = swapBranchesInCondition(
        targetNodeId,
        workflow,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SET_CONDITION_REASON: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      branch: Joi.string().valid('if_true', 'if_false').required(),
      reason: Joi.string().allow('').required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, branch, reason,
      } = actionData;
      const updatedWorkflow = editIfTrueIfFalseReason(
        workflow,
        targetNodeId,
        branch,
        reason,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  // TODO handling the failure case in UI
  SET_CONDITION_RESUME_FROM: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      branch: Joi.string().valid('if_true', 'if_false').required(),
      value: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, branch, value,
      } = actionData;
      const { workflow: updatedWorkflow, success } = editResumeFrom(
        workflow,
        targetNodeId,
        branch,
        value,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  SET_CONDITION_RULE: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      rule: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, rule,
      } = actionData;
      const updatedWorkflow = updateConditionRule(
        workflow,
        targetNodeId,
        rule,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  SET_NODE_NAME: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      name: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, name,
      } = actionData;
      const { workflow: updatedWorkflow, success } = updateNodeName(
        workflow,
        targetNodeId,
        name,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  SET_VALUE_IN_WORKFLOW: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      rootWorkflowKey: Joi.string().required(),
      value: Joi.any().required(),
      moduleConfig: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, rootWorkflowKey, value, moduleConfig,
      } = actionData;
      const updatedWorkflow = setValueInWorkflow(
        workflow,
        value,
        rootWorkflowKey,
        targetNodeId,
        moduleConfig,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  UNSET_VALUE_IN_WORKFLOW: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      rootWorkflowKey: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, rootWorkflowKey,
      } = actionData;
      const updatedWorkflow = unSetValueInWorkflow(
        workflow,
        rootWorkflowKey,
        targetNodeId,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  ADD_FORM_COMPONENT_V2: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      targetComponentId: Joi.string().min(1).allow(null),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, targetComponentId,
      } = actionData;
      const { workflow: updatedWorkflow, success } = addFormComponentInV2(
        workflow,
        targetNodeId,
        targetComponentId,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  DELETE_FORM_COMPONENT_V2: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      targetComponentId: Joi.string().min(1).allow(null),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, targetComponentId,
      } = actionData;
      const { workflow: updatedWorkflow, success } = deleteFormComponentInV2(
        workflow,
        targetNodeId,
        targetComponentId,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  CLONE_FORM_COMPONENT_V2: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      targetComponentId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, targetComponentId,
      } = actionData;
      const { workflow: updatedWorkflow, success } = cloneFormComponentInV2(
        workflow,
        targetNodeId,
        targetComponentId,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  UPDATE_TAG_IN_FORM_COMPONENT_V2: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      targetComponentId: Joi.string().required(),
      tagName: Joi.string().required(),
      libraryUrl: Joi.string().allow('').required(),
      libraryId: Joi.string().allow('').required(),
      formComponentId: Joi.string().allow('').required(),
      styleSheetsUrl: Joi.string().allow('').required(),
      defaultAttributes: Joi.object().pattern(
        Joi.string(),
        Joi.string().allow(''),
      ).required(),
      defaultProperties: Joi.object().pattern(
        Joi.string(),
        Joi.alternatives().try(
          Joi.string().allow(''),
          Joi.number(),
          Joi.valid(null),
        ),
      ).required(),
      defaultStyles: Joi.object().pattern(
        Joi.string(),
        Joi.alternatives().try(
          Joi.string().allow(''),
          Joi.number(),
          Joi.valid(null),
        ),
      ).required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, targetComponentId, tagName, libraryUrl = '', styleSheetsUrl = '',
        libraryId = '', formComponentId = '',
        defaultProperties = {}, defaultAttributes = {}, defaultStyles = {},
      } = actionData;
      const { workflow: updatedWorkflow, success } = updateTagNameOfComponentInFormV2(
        workflow,
        targetNodeId,
        targetComponentId,
        tagName,
        libraryUrl,
        styleSheetsUrl,
        defaultAttributes,
        defaultProperties,
        defaultStyles,
        libraryId,
        formComponentId,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  DRAG_FORM_COMPONENT_V2: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      pickComponentId: Joi.string().required(),
      dropComponentId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, pickComponentId, dropComponentId,
      } = actionData;
      const { workflow: updatedWorkflow, success } = dragComponentInFormV2(
        workflow,
        targetNodeId,
        pickComponentId,
        dropComponentId,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  ADD_FORM_COMPONENT_V2_VIA_CLIPBOARD: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      htmlString: Joi.string().required(),
      componentConfigsFromContextCopy: Joi.object(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId, htmlString, componentConfigsFromContextCopy,
      } = actionData;
      const { workflow: updatedWorkflow, success } = addFormComponentViaClipboardInFormV2(
        workflow,
        targetNodeId,
        htmlString,
        componentConfigsFromContextCopy,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  SET_PREVIOUS_STEP: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
      previousStep: Joi.string().required().allow(''),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
        previousStep,
      } = actionData;
      const updatedWorkflow = setPreviousStepInWorkflow(
        workflow,
        targetNodeId,
        previousStep,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },
  UNSET_PREVIOUS_STEP: {
    actionDataSchema: Joi.object({
      targetNodeId: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        targetNodeId,
      } = actionData;
      const updatedWorkflow = unsetPreviousStepInWorkflow(
        workflow,
        targetNodeId,
      );
      return { workflow: updatedWorkflow, success: true };
    },
  },

  DRAG_COMPONENT: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      fromComponentId: Joi.string().required(),
      toComponentId: Joi.string().required(),
      rootPath: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId, fromComponentId, toComponentId, rootPath,
      } = actionData;
      const { workflow: updatedWorkflow, success } = dragComponentWrapper(
        workflow,
        moduleId,
        fromComponentId,
        toComponentId,
        rootPath,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  DRAG_COMPONENT_IN_SUPER_MODULE: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      fromComponentId: Joi.string().required(),
      toComponentId: Joi.string().required(),
      rootPath: Joi.string().required(),
      compiledWorkflow: Joi.any(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId, fromComponentId, toComponentId, rootPath, compiledWorkflow,
      } = actionData;

      const { workflow: updatedWorkflow, success } = dragComponentInsideSuperModuleWrapper(
        compiledWorkflow,
        moduleId,
        fromComponentId,
        toComponentId,
        rootPath,
        workflow,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  ADD_COMPONENT: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      defaultConfig: Joi.object().required(),
      componentId: Joi.string().allow(''),
      rootPath: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId, defaultConfig, componentId, rootPath,
      } = actionData;

      const {
        operatedWorkflow, success, toPathArray, indexToAddInPathArray,
      } = addComponentWrapper(workflow, moduleId, defaultConfig, rootPath, componentId);
      if (success) {
        return {
          workflow: operatedWorkflow, success, extraData: { toPathArray, indexToAddInPathArray },
        };
      }
      return { workflow, success: false };
    },
  },
  UPDATE_FORM_PROPERTY_IN_SUPER_MODULE: {
    actionDataSchema: Joi.object({
      superModuleId: Joi.string().required(),
      mappingId: Joi.string().required(),
      componentId: Joi.string().required(),
      key: Joi.string().required(),
      value: Joi.any().required(),
      isUIProperty: Joi.boolean().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const {
        superModuleId,
        mappingId,
        componentId,
        key,
        value,
        isUIProperty,
      } = actionData;
      return updateFormPropertyInSuperModule({
        workflow,
        uiConfig,
        superModuleId,
        mappingId,
        componentId,
        key,
        value,
        isUIProperty,
      });
    },
  },
  UPDATE_FORM_PROPERTY: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      componentId: Joi.string().required(),
      isUIProperty: Joi.boolean().required(),
      key: Joi.string().required(),
      value: Joi.any().required(),
    }),
    operatingFn: (workflow, actionData, uiConfig) => {
      const {
        key,
        value,
        moduleId,
        componentId,
        isUIProperty,
      } = actionData;
      return updateFormProperty({
        workflow,
        uiConfig,
        key,
        value,
        moduleId,
        componentId,
        isUIProperty,
      });
    },
  },
  UPDATE_COMPONENT: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      newComponent: Joi.object().required(),
      pathArray: Joi.array().required(),
      rootPath: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId, newComponent, pathArray, rootPath,
      } = actionData;
      const { workflow: updatedWorkflow, success } = updateComponentWrapper(
        workflow,
        moduleId,
        newComponent,
        pathArray,
        rootPath,
      );
      if (success) return { workflow: updatedWorkflow, success };
      return { workflow, success: false };
    },
  },
  COPY_COMPONENT: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      componentId: Joi.string().allow(''),
      rootPath: Joi.string().required(),
      formComponentList: Joi.array().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId, componentId, rootPath, formComponentList,
      } = actionData;

      const { workflow: updatedWorkflow, success, originalToClonedComponentIdMap } =
      copyComponentWrapper(
        workflow,
        moduleId,
        rootPath,
        componentId,
        formComponentList,
      );
      if (success) {
        return {
          workflow: updatedWorkflow,
          success,
          extraData: originalToClonedComponentIdMap,
        };
      }
      return { workflow, success: false };
    },
  },

  ADD_FORM_COMPONENT_VIA_CLIPBOARD: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      updatedComponent: Joi.object(),
      rootPath: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId, updatedComponent, rootPath,
      } = actionData;
      const { updatedWorkflow, success } = pasteInDynamicForm(
        workflow,
        moduleId,
        updatedComponent,
        rootPath,
      );
      if (success) {
        return {
          workflow: updatedWorkflow,
          success,
        };
      }
      return { workflow, success: false };
    },
  },

  DELETE_COMPONENT: {
    actionDataSchema: Joi.object({
      componentId: Joi.string().required(),
      rootPath: Joi.string().required(),
      moduleId: Joi.string().required(),
      checkDepsFn: Joi.any(),
      selectedComponentPathArray: Joi.array(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        componentId, rootPath, moduleId, checkDepsFn, selectedComponentPathArray,
      } = actionData;
      const { workflow: updatedWorkflow, success, extraData } = deleteComponentWrapper(
        workflow,
        moduleId,
        rootPath,
        componentId,
        selectedComponentPathArray,
        checkDepsFn,
      );

      if (success) {
        return {
          workflow: updatedWorkflow,
          success,
          extraData,
        };
      }
      return { workflow, success: false };
    },
  },
  SET_MODULE_BUILDER_PROPERTIES: {
    actionDataSchema: Joi.object({
      path: Joi.string().required(),
      builderProperties: Joi.object().pattern(
        Joi.string(),
        Joi.object({
          config: inputSchemaForModuleConfig,
          configSectionName: Joi.string().required(),
          uiConfig: inputStructureSchema,
          uiConfigSectionName: Joi.string().required(),
          workflowKey: Joi.string().required(),
        }),
      ).required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { builderProperties, path } = actionData;
      const { updatedWorkflow, success } = setModuleBuilderConfigurations(
        workflow,
        builderProperties,
        path,
      );
      return { workflow: updatedWorkflow, success };
    },
  },
  SET_MODULE_BUILDER_INPUTS: {
    actionDataSchema: Joi.object({
      path: Joi.string().required(),
      inputs: Joi.object().pattern(
        Joi.string(),
        Joi.object({
          config: inputSchemaForModuleConfig,
          configSectionName: Joi.string().required(),
          uiConfig: inputStructureSchema,
          uiConfigSectionName: Joi.string().required(),
          workflowKey: Joi.string().required(),
        }),
      ).required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { inputs, path } = actionData;
      const { updatedWorkflow, success } = setModuleBuilderConfigurations(workflow, inputs, path);
      return { workflow: updatedWorkflow, success };
    },
  },
  SET_MODULE_BUILDER_OUTPUTS: {
    actionDataSchema: Joi.object({
      path: Joi.string().required(),
      outputs: Joi.object().pattern(
        Joi.string(),
        Joi.object({
          name: Joi.string().required(),
          type: Joi.string().required(),
          path: Joi.string().required(),
          description: Joi.string().allow(''),
          displayName: Joi.string().required().allow(''),
        }),
      ).required(),
    }),
    operatingFn: (workflow, actionData) => {
      const { outputs, path } = actionData;
      const { updatedWorkflow, success } = setModuleBuilderConfigurations(workflow, outputs, path);
      return { workflow: updatedWorkflow, success };
    },
  },
  UPDATE_SELECTED_COUNTRIES: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      countriesSelected: Joi.array().required(),
      globalDocumentList: Joi.array().required(),
      workflowKey: Joi.string().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId,
        countriesSelected,
        globalDocumentList,
        workflowKey,
      } = actionData;
      const { workflow: updatedWorkflow, isSuccess } = updateCountriesInWorkflow(
        workflow,
        moduleId,
        countriesSelected,
        globalDocumentList,
        workflowKey,
      );
      return { workflow: updatedWorkflow, success: isSuccess };
    },
  },
  UPDATE_SELECTED_DOCUMENTS: {
    actionDataSchema: Joi.object({
      moduleId: Joi.string().required(),
      documentsSelected: Joi.array().required(),
      moduleConfig: Joi.object().required(),
    }),
    operatingFn: (workflow, actionData) => {
      const {
        moduleId,
        documentsSelected,
        moduleConfig,
      } = actionData;
      const { workflow: updatedWorkflow, isSuccess } = updateDocumentsInWorkflow(
        workflow,
        moduleId,
        documentsSelected,
        moduleConfig,
      );
      return { workflow: updatedWorkflow, success: isSuccess };
    },
  },
};

export const supportedWorkflowOperations = Object.keys(workflowOperations);

export const workflowOperationsObj = supportedWorkflowOperations
  .reduce((acc, curr) => ({ [curr]: curr, ...acc }), { });

export const operateOnWorkflow = (workflow, action, uiConfig) => {
  try {
    const { operation = null, actionData = {} } = action;

    // TODO: success is true because the operation is not yet migrated,
    // Will make it false once all are migrated.
    if (!supportedWorkflowOperations.includes(operation)) return { workflow, success: true };
    const { actionDataSchema, operatingFn } = workflowOperations[operation];
    const { error } = actionDataSchema.validate(actionData);
    // TODO: Log error here ?
    if (error === null) return operatingFn(workflow, actionData, uiConfig);
    return { workflow, success: false };
  } catch (err) {
    // TODO: Log error here?
    return { workflow, success: false };
  }
};
