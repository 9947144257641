/* eslint-disable no-param-reassign */

import { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useApiHooks from '../hooks/api/useApiHooks';
import AlertCode from '../constants/alertCodes';
import {
  resetSelectedWorkflow,
  selectIsWorkflowEdited,
  selectSelectedWorkflow,
  selectVersionedModules,
  undoSelectedWorkflow,
  updateVersionedModules,
  resetIsEditedWorkflow,
} from '../reducers/workflow';
import { updateWorkflowInState } from '../workflowOperations/updateWorkflow';
import useShowErrorAlert from '../hooks/custom/useCustomAlert';
import {
  resetBrandingScreens,
  selectSelectedLanguage,
  updateCustomTextConfig,
  updateCustomUiConfig,
  updateSelectedLanguage,
  updateFontStyleSheets,
} from '../reducers/editBranding';
import ErrorBoundaryWrapper from '../containers/ErrorBoundaryWrapper';
import FallbackComponent from '../components/Common/FallbackComponent';
import { closeExistingSDK } from '../components/utils';
import {
  replaceActualModuleIdsWithSuperModuleIds,
  extractCustomFontsFromUiConfig,
} from '../containers/uiConfigOperations';
import useFetchWorkflow from '../hooks/workflow/fetchWorkflow';
import processModuleBuilderProperties from '../utils/processModuleBuilderproperties';

function WorkflowRouter() {
  const {
    fetchCustomTextConfig, fetchCustomUiConfig, getDefaultTextConfig,
  } = useApiHooks();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const workflowId = searchParams.get('id');

  const currentAppId = useSelector((state) => state.user.currentAppId);
  const workflowConfig = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const isWorkflowEdited = useSelector(selectIsWorkflowEdited);
  const selectedLanguage = useSelector(selectSelectedLanguage);

  const fetchWorkflow = useFetchWorkflow(currentAppId, workflowConfig, versionedModules);

  const showErrorAlert = useShowErrorAlert();

  const changeCSS = () => {
    const style = document.createElement('style');
    style.innerHTML = '.react-flow__handle.connectable { visibility: hidden;}';
    document.getElementsByTagName('head')[0].appendChild(style);
  };

  // fetching the workflow by workflow id
  useEffect(() => {
    getDefaultTextConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!workflowId) {
      navigate('/');
    }
    (async () => {
      try {
        const {
          workflow: decompiledWorkflow, rawWorkflow, modules,
        } = await fetchWorkflow(workflowId);
        if (decompiledWorkflow && modules) {
          dispatch(updateVersionedModules({ modules }));
          dispatch(resetIsEditedWorkflow());
        }

        // For modules which have already been imported with non empty configs.
        const workflow = processModuleBuilderProperties(decompiledWorkflow);

        updateWorkflowInState(workflow, false);
        changeCSS();
        dispatch(resetBrandingScreens());
        const uiConfig = await fetchCustomUiConfig(workflowId);
        const highLevelUiConfig =
          replaceActualModuleIdsWithSuperModuleIds(rawWorkflow?.modules || [], uiConfig);
        const textConfig = await fetchCustomTextConfig(workflowId, selectedLanguage);
        if (highLevelUiConfig) dispatch(updateCustomUiConfig({ uiConfig: highLevelUiConfig }));
        if (textConfig) {
          dispatch(updateCustomTextConfig({ textConfig, language: selectedLanguage }));
        }
        const customFonts = extractCustomFontsFromUiConfig(highLevelUiConfig);
        const customFontStyleSheets = customFonts.reduce((styleSheets, font) => ({
          ...styleSheets,
          [font]: (process.env.REACT_APP_CUSTOM_FONT_URL || '').replace('<SELECTED_FONT>', font),
        }), {});
        dispatch(updateFontStyleSheets({ fontStyleSheets: customFontStyleSheets }));
      } catch (error) {
        showErrorAlert({ error, message: error?.message || AlertCode.error.fetchWorkflow });
      }
    })();

    return () => {
      dispatch(resetSelectedWorkflow());
      dispatch(updateCustomUiConfig({ uiConfig: {} }));
      dispatch(updateCustomTextConfig({ textConfig: {} }));
      dispatch(updateSelectedLanguage({ language: 'en' }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowId]);

  useEffect(() => {
    const beforeUnloadListener = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-return-assign
      return (event.returnValue = '');
    };
    if (isWorkflowEdited) {
      window.addEventListener('beforeunload', beforeUnloadListener);
      window.addEventListener('pagehide', beforeUnloadListener);
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadListener);
        window.removeEventListener('pagehide', beforeUnloadListener);
      };
    }
    return () => { };
  }, [isWorkflowEdited]);

  return (
    <>
      {Object.keys(workflowConfig || {}).length === 0 ? (
        <div className="reload-loading">loading please wait</div>
      ) :
        <Outlet />}
    </>
  );
}

function WorkflowRouterWrapper() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const workflowId = searchParams.get('id');

  return (
    <ErrorBoundaryWrapper
      FallbackComponent={FallbackComponent}
      resetFunction={() => {
        // reset the state of your app here
        closeExistingSDK();
        dispatch(undoSelectedWorkflow());
        navigate(`/view?id=${workflowId}`);
      }}
    >
      <WorkflowRouter />
    </ErrorBoundaryWrapper>
  );
}

export default WorkflowRouterWrapper;
