import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import CopyIcon from '../../assests/icons/contentCopyIcon.svg';
import FormModuleGenericEditorV2 from './FormModuleGenericEditorV2';
import DropDownWithCustomInput from './Common/DropDownWithCustomInput';
import GenericEditor from './GenericEditor';
import {
  getComponentConfigurations,
  getComponentConfigByIds,
  getFormV2ComponentOptions,
  getLibraryById,
  getLibraryForFormComponent,
} from '../../utils/formV2Components';

function FormModuleEditPropertiesV2(props) {
  const {
    formComponentLibraries,
    selectedComponentTagName,
    selectedModuleId,
    selectedComponent,
    selectedComponentId,
    onUpdateComponent,
    onTagNameUpdate,
  } = props;

  const [copyAlert, setCopyAlert] = useState(false);
  const timerRef = useRef();

  const setAlert = () => {
    clearTimeout(timerRef.current);
    setCopyAlert(true);
    timerRef.current = setTimeout(() => {
      setCopyAlert(false);
    }, 5000);
  };

  const getCurrentValue = (component, property, componentDefaultValue) => {
    const configuredValue = get(component, property);
    if (typeof configuredValue === 'undefined') return componentDefaultValue;
    return configuredValue;
  };

  const getSelectedFormComponentType = (component) => {
    const { componentRef = {} } = component;
    const { libraryId = '', formComponentId = '' } = componentRef;
    return libraryId && formComponentId ? `${libraryId}+${formComponentId}` : '';
  };

  const handleTypeChange = (newType) => {
    if (!newType || !newType.includes('+')) {
      if (newType?.trim()) {
        onTagNameUpdate(newType, '', '', {}, { libraryId: '', formComponentId: '' });
      }
      return;
    }
    const [libraryId, formComponentId] = newType.split('+');
    const { tagName = '', configurations = [] } = getComponentConfigByIds(
      formComponentLibraries,
      libraryId,
      formComponentId,
    );

    if (!tagName) return;

    const defaults = (configurations || []).reduce((acc, { key, type, valueSpecs }) => {
      const defaultValue = valueSpecs?.default;
      if (typeof defaultValue !== 'undefined') {
        acc[type] = { [key]: defaultValue, ...acc[type] };
      }
      return acc;
    }, {});

    const library = getLibraryById(formComponentLibraries, libraryId);
    const libraryUrl = library?.libraryUrl || '';
    const styleSheetsUrl = library?.styleSheetsUrl || '';

    onTagNameUpdate(tagName, libraryUrl, styleSheetsUrl, defaults, {
      libraryId,
      formComponentId,
    });
  };

  return (
    <div className="edit-properties-div">
      <div className="edit-properties-div__property">
        <Grid container alignItems="center" className="moduleId">
          <Grid item xs={4}>
            Module Id
          </Grid>
          <Grid item xs={8}>
            <div className="edit-properties-div__text-container">
              <input
                type="text"
                className="edit-properties-div__text-input"
                value={selectedModuleId}
                readOnly
              />
              <button type="button" className="edit-properties-div__text-copy">
                <CopyToClipboard text={selectedModuleId} onCopy={setAlert}>
                  <img src={CopyIcon} alt="Copy" />
                </CopyToClipboard>
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="edit-properties-div__property">
        <Grid container alignItems="center" className="moduleId">
          <Grid item xs={4}>
            Component Id
          </Grid>
          <Grid item xs={8}>
            <div className="edit-properties-div__text-container">
              <input
                type="text"
                className="edit-properties-div__text-input"
                value={selectedComponentId}
                readOnly
              />
              <button type="button" className="edit-properties-div__text-copy">
                <CopyToClipboard text={selectedComponentId} onCopy={setAlert}>
                  <img src={CopyIcon} alt="Copy" />
                </CopyToClipboard>
              </button>
            </div>
          </Grid>
        </Grid>
        {copyAlert && <div className="edit-properties-div__alert">copied!</div>}
      </div>
      <div className="edit-properties-div__property">
        <DropDownWithCustomInput
          key={`${selectedModuleId}_${selectedComponentId}`}
          label="Type"
          options={getFormV2ComponentOptions(formComponentLibraries)}
          onChange={handleTypeChange}
          defaultValue={getSelectedFormComponentType(selectedComponent) || selectedComponentTagName}
          allowOnlyNumbers={false}
        />
      </div>
      {getLibraryForFormComponent(selectedComponent, formComponentLibraries) ? (
        <div className="edit-properties-div__property">
          {getComponentConfigurations(selectedComponent, formComponentLibraries).map(
            ({
              key,
              type,
              valueSpecs,
              defaultComponentValue,
            }) => (
              <GenericEditor
                key={`${key}_${selectedComponentId}`}
                specs={valueSpecs}
                onChange={(newValue) => {
                  onUpdateComponent(`${type}.${key}`, newValue);
                }}
                defaultValue={getCurrentValue(
                  selectedComponent,
                  `${type}.${key}`,
                  defaultComponentValue,
                )}
              />
            ),
          )}
          <FormModuleGenericEditorV2
            key={`${selectedModuleId}_${selectedComponentId}`}
            component={selectedComponent}
            domId={selectedComponentId}
            handleComponentPropertyChange={onUpdateComponent}
            propsToRender={[
              {
                label: 'CSS',
                property: 'css',
                formatAsJson: true,
                type: 'cssEditor',
              },
              {
                label: 'Events',
                property: 'events',
                formatAsJson: true,
                type: 'jsonEditor',
              },
              {
                label: 'Dynamic Handlers',
                property: 'dynamicHandlers',
                formatAsJson: true,
                type: 'jsonEditor',
                rootName: 'dynamicHandlers',
              },
            ]}
          />
        </div>
      ) : (
        <FormModuleGenericEditorV2
          key={`${selectedModuleId}_${selectedComponentId}`}
          component={selectedComponent}
          domId={selectedComponentId}
          handleComponentPropertyChange={onUpdateComponent}
          propsToRender={[
            {
              label: 'Attributes',
              property: 'attributes',
              formatAsJson: true,
              type: 'jsonBuilder',
            },
            {
              label: 'Properties',
              property: 'properties',
              formatAsJson: true,
              type: 'jsonBuilder',
            },
            {
              label: 'Style Variables',
              property: 'styles',
              formatAsJson: true,
              type: 'jsonBuilder',
            },
            {
              label: 'SDK Attributes',
              property: 'sdkController',
              formatAsJson: true,
              type: 'jsonBuilder',
            },
            {
              label: 'CSS',
              property: 'css',
              formatAsJson: true,
              type: 'cssEditor',
            },
            {
              label: 'Events',
              property: 'events',
              formatAsJson: true,
              type: 'jsonEditor',
            },
            {
              label: 'Dynamic Handlers',
              property: 'dynamicHandlers',
              formatAsJson: true,
              type: 'jsonEditor',
              rootName: 'dynamicHandlers',
            },
          ]}
        />
      )}
    </div>
  );
}

export default FormModuleEditPropertiesV2;

FormModuleEditPropertiesV2.propTypes = {
  selectedComponentTagName: PropTypes.string.isRequired,
  selectedModuleId: PropTypes.string.isRequired,
  selectedComponentId: PropTypes.string.isRequired,
  selectedComponent: PropTypes.object.isRequired,
  onUpdateComponent: PropTypes.func.isRequired,
  onTagNameUpdate: PropTypes.func.isRequired,
  formComponentLibraries: PropTypes.array.isRequired,
};
