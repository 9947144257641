import {
  useState, useRef, useCallback, useEffect,
} from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
} from 'reactflow';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { convertToConfig } from '../components/utils';
import Sidebar from '../components/Sidebar';
import { endStates } from '../components/constants';
import ModuleNode from '../components/ModuleNode';
import AlertCode from '../constants/alertCodes';
import useShowErrorAlert, { useShowCustomAlert } from '../hooks/custom/useCustomAlert';
import { selectVersionedModules } from '../reducers/workflow';

// TODO: Remove this file as it is unused
export default function Builder() {
  const globalConfig = useSelector((state) => state.workflow.globalConfig);
  const [countriesSupported, setCountriesSupported] = useState([]);
  const [documentsSupported, setDocumentsSupported] = useState({});
  const versionedModules = useSelector(selectVersionedModules);
  const showErrorAlert = useShowErrorAlert();

  let id = 0;
  let id2 = 0;
  const getId = (nodeType) => {
    let nodeId = '';
    if (Object.keys(endStates).includes(nodeType)) {
      nodeId = `${nodeType}_${id2}`;
      id2 += 1;
    } else {
      nodeId = `mod_${id}`;
      id += 1;
    }
    return nodeId;
  };

  useEffect(() => {
    const countries = globalConfig.map((config) => (config.id));
    setCountriesSupported(countries);
    const documents = {};
    globalConfig.forEach((config) => {
      const d = config.documents.map((doc) => doc.id);
      documents[config.id] = d;
    });
    setDocumentsSupported(documents);
  }, [globalConfig]);

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const showCustomAlert = useShowCustomAlert();

  const onSubmitButtonClick = async () => {
    const workflowConfig = convertToConfig({ nodes, edges });

    const updatedWorkflowConfig = workflowConfig;

    const { modules } = updatedWorkflowConfig;
    const idx = modules.findIndex((element) => element.subType === 'countries');

    if (idx !== -1) {
      modules[idx].properties = { countriesSupported };
    }

    const docIdx = [];
    modules.forEach((module, i) => {
      if (module.subType === 'id_card_verification') docIdx.push(i);
    });
    docIdx.forEach((doc) => {
      const update = modules[doc];
      update.properties = { documentsSupported };
      updatedWorkflowConfig.modules[doc] = update;
    });

    const workflowId = uuidv4();
    try {
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow/`,
        headers: { appId: currentAppId },
        data: { workflowId, workflowConfig: updatedWorkflowConfig },
      });
      showCustomAlert({
        message: workflowId,
        alertSeverity: 'success',
      });
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.errorUploadingWorkflow });
    }
  };

  const onConnect = useCallback((params) => setEdges((eds) => addEdge({ ...params, type: 'step' }, eds)), []);

  const onNodeDragStop = useCallback((event, node) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    node.position.x = (Math.round(node.position.x / 100) * 100);
    // eslint-disable-next-line no-param-reassign
    node.position.y = Math.round(node.position.y / 100) * 100;
  });

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const data = JSON.parse(event.dataTransfer.getData('application/reactflow'));
      const { nodeType, version = 'v1' } = data;
      const { nodeDisplayType, uiConfig } = versionedModules[nodeType]?.[version]?.config || {};

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const position = reactFlowInstance.project({
        x: (Math.round((event.clientX - reactFlowBounds.left) / 100) * 100),
        y: Math.round((event.clientY - reactFlowBounds.top) / 100) * 100,
      });

      const newNode = {
        id: getId(nodeType),
        type: nodeDisplayType,
        position,
        data: {
          label: <ModuleNode
            heading={uiConfig.node.heading}
            subHeading={uiConfig.node.subHeading}
            type={uiConfig.node.nodeType}
          />,
        },
        nodeType,
        className: 'nodes__module',
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [versionedModules, reactFlowInstance, setNodes],
  );
  return (
    <div className="dndflow view_workflow__workflow_container" style={{ height: '1000px' }}>
      <ReactFlowProvider>
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeDragStop={onNodeDragStop}
            zoomOnPinch={false}
            zoomOnScroll={false}
            panOnScroll
            panOnScrollMode="vertical"
          >
            <Controls />
          </ReactFlow>
        </div>
        <Sidebar onSubmitButtonClick={onSubmitButtonClick} />
      </ReactFlowProvider>
    </div>
  );
}
