import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import JSZip from 'jszip';

import Button from '@mui/material/Button';

import '../../containers/Container.scss';
import '../WorkflowList/WorkflowList.scss';
import { useLocation } from 'react-router-dom';
import {
  selectSelectedWorkflow, selectVersionedModules, resetIsEditedWorkflow, updateVersionedModules,
} from '../../reducers/workflow';
import compile from '../../compilerDecompiler/compiler';
import {
  generateModuleConfigsFromWorkflow,
  generateModuleUiConfigFromWorkflow,
} from '../../compilerDecompiler/workflowModuleTransformer';
import { selectModuleBuilderMode } from '../../reducers/moduleBuilder';
import { selectCustomTextConfig, selectCustomUIConfig, selectSelectedLanguage } from '../../reducers/editBranding';
import { compileUiConfig } from '../../containers/uiConfigOperations';
import DownloadIcon from '../../assests/icons/downloadIcon.svg';
import { formComponentList } from '../../constants/dynamicFormComponents';
import useFetchWorkflow from '../../hooks/workflow/fetchWorkflow';
import useApiHooks from '../../hooks/api/useApiHooks';

function DownloadWorkflow({ id, isDownloadTriggeredFromTable }) {
  const dispatch = useDispatch();
  const { fetchCustomTextConfig, fetchCustomUiConfig } = useApiHooks();
  const { search } = useLocation();
  const selectedWorkflowFromState = useSelector(selectSelectedWorkflow);
  const customTextConfigFromState = useSelector(selectCustomTextConfig);
  const customUiConfigFromState = useSelector(selectCustomUIConfig);
  const versionedModules = useSelector(selectVersionedModules);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const moduleBuilderMode = useSelector(selectModuleBuilderMode);
  const workflowId = new URLSearchParams(search).get('id') || id;
  const currentAppId = useSelector((state) => state.user.currentAppId);

  const fetchWorkflow = useFetchWorkflow(currentAppId, selectedWorkflowFromState, versionedModules);

  const getConfig = async (stateConfig, fetchFunction, ...args) => {
    if (isDownloadTriggeredFromTable) {
      return fetchFunction(...args);
    }
    return stateConfig;
  };

  const handleClick = async (e) => {
    e.stopPropagation();

    const [workflow, customTextConfig, customUiConfig] = await Promise.all([
      getConfig(selectedWorkflowFromState, fetchWorkflow, workflowId),
      getConfig(customTextConfigFromState, fetchCustomTextConfig, workflowId, selectedLanguage),
      getConfig(customUiConfigFromState, fetchCustomUiConfig, workflowId),
    ]);

    if (workflow && isDownloadTriggeredFromTable) {
      dispatch(updateVersionedModules({ modules: workflow.modules }));
      dispatch(resetIsEditedWorkflow());
    }

    const selectedWorkflow = workflow?.workflow ? workflow.workflow : workflow;

    const compiledWorkflow = compile(selectedWorkflow, versionedModules, formComponentList);
    const zip = new JSZip();

    if (moduleBuilderMode) {
      const moduleConfig = generateModuleConfigsFromWorkflow(
        compiledWorkflow,
        customUiConfig,
        customTextConfig,
      );
      const uiConfig = generateModuleUiConfigFromWorkflow(compiledWorkflow);
      zip.file('moduleConfig.json', JSON.stringify(moduleConfig));
      zip.file('uiConfig.json', JSON.stringify(uiConfig));
    } else {
      // Add JSON files to the ZIP
      zip.file(`${workflowId}.json`, JSON.stringify(compiledWorkflow));
      if (Object.keys(customUiConfig).length > 0) {
        const compiledUiConfig = compileUiConfig(customUiConfig, compiledWorkflow);
        zip.file(`${workflowId}_ui_config.json`, JSON.stringify(compiledUiConfig));
      }
      if (Object.keys(customTextConfig).length > 0) {
        zip.file(`${workflowId}_${selectedLanguage || 'en'}_text_config.json`, JSON.stringify(customTextConfig));
      }
    }

    // Generate the ZIP blob
    const content = await zip.generateAsync({ type: 'blob' });

    const url = URL.createObjectURL(content);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${workflowId}.zip`;
    a.click();
    URL.revokeObjectURL(url);
  };
  if (!id) {
    return (
      <Button
        variant="outlined"
        onClick={handleClick}
        className="more-options__option"
      >
        <img className="more-options__option-icon" src={DownloadIcon} alt="Download Icon" />
        <span className="more-options__option-text">Download Workflow</span>
      </Button>
    );
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      id="workflow_table__downloadIcon"
    >
      <img id="workflow_table__downloadIcon-img" src={DownloadIcon} alt="Download Icon" />
    </button>
  );
}

DownloadWorkflow.propTypes = {
  id: PropTypes.string,
  isDownloadTriggeredFromTable: PropTypes.bool,
};

DownloadWorkflow.defaultProps = {
  id: '',
  isDownloadTriggeredFromTable: false,
};

export default DownloadWorkflow;
