import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import generateUniqueID from '../../../utils/generateUniqueId';
import {
  removeUniqueIdFromArrayElements,
  reshapeArrayStateToObjectData,
  reshapeObjectDataToArrayState,
} from '../../../utils/helper';
import PanelItem from './panelItem';
import TabItem from './tabItem';
import EditConfiguration from './EditConfiguration';
import BodyParamToggle from './BodyParamToggle';
import Header from './Header';
import {
  ifOnlyReqParamsAndBodyInPanel,
  getInitialGenericApiConfig,
} from './helper';
import '../NewConditionDrawer/NewConditionDrawer.scss';
import withDeletionDependencyCheck from '../../../utils/withDeletionDependencyCheck';
import {
  getConditionalVariables, getModuleOutputs, getModuleProperties, getWorkflowInputVariables,
} from '../InputsToModule/utils';
import { createNestedMenuData } from '../NestedDropdown/utils';
import { formComponentList as formComponents } from '../../../constants/dynamicFormComponents';

function ConfigurationModal({
  config,
  selectedNodeId,
  selectedNodeType,
  selectedNodeVersion,
  selectedWorkflow,
  versionedModules,
  orderOfModules,
  toggleModal,
  checkDependencies: checkDeletionDependencies,
  curlInput,
  updateConfigValueInWorkflow,
}) {
  const { texts, panels } = config;
  const { title: titleText, save: saveText } = texts;
  const [panelIndex, setPanelIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [toggleState, setToggleState] = useState(null);
  const selectedModuleConfig = versionedModules?.[selectedNodeType]?.[selectedNodeVersion]?.config;
  const selectedWorkflowModules = selectedWorkflow.modules;
  const selectedModule = selectedWorkflowModules.find(
    (module) => module.id === selectedNodeId,
  );

  const menuOptions = useMemo(() => {
    const moduleOutputOptions = getModuleOutputs(
      orderOfModules,
      selectedNodeId,
      selectedWorkflow,
      formComponents,
      versionedModules,
    );
    const conditionalVariableOptions = getConditionalVariables(selectedWorkflow);
    const workflowInputOptions = getWorkflowInputVariables(selectedWorkflow);
    const moduleProperties = getModuleProperties(selectedWorkflow);
    return createNestedMenuData(
      workflowInputOptions,
      conditionalVariableOptions,
      moduleOutputOptions,
      moduleProperties,
    );
  }, [
    formComponents,
    versionedModules,
    orderOfModules,
    selectedNodeId,
    selectedWorkflow,
  ]);

  const loadedData = getInitialGenericApiConfig(panels, selectedModule);
  const [tempStore, setTempStore] = useState(loadedData);

  const updateTempStore = (data, pIndex, tIndex, store) => {
    const newState = cloneDeep(store);
    newState[`${pIndex}_${tIndex}`] = data;
    setTempStore(newState);
  };

  const handleToggle = (value) => {
    setToggleState(value === 'param' || value === 'body' ? value : null);
  };

  const saveData = (currentPanels, store, moduleId, moduleConfig) => {
    currentPanels.forEach(({ tabs }, panelI) => {
      tabs.forEach(({ rootWorkflowKey, type }, tabI) => {
        const rawData = store[`${panelI}_${tabI}`];
        let newValue = rawData;
        if (type === 'moduleVariables' || type === 'reqParams') {
          newValue = removeUniqueIdFromArrayElements(rawData);
        } else if (type === 'typedSimpleLinearObject' || type === 'simpleLinearObject') {
          newValue = reshapeArrayStateToObjectData(rawData, type === 'typedSimpleLinearObject');
        } else {
          newValue = rawData;
        }
        updateConfigValueInWorkflow(rootWorkflowKey, newValue, moduleId, moduleConfig);
      });
    });
    toggleModal();
  };

  const onPanelSelect = (currentPanelIndex, selectedIndex) => {
    if (currentPanelIndex !== selectedIndex) setTabIndex(0);
    setPanelIndex(selectedIndex);
  };

  const onTabSelect = (tabNumber) => {
    setTabIndex(tabNumber);
  };

  const isTabHidden = (state, rootKey) => {
    if (state !== null) {
      if (state === 'param' && rootKey === 'properties.requestBody') return true;
      if (state === 'body' && rootKey === 'properties.requestParameters') return true;
    }
    return false;
  };

  useEffect(() => {
    const showToggle = ifOnlyReqParamsAndBodyInPanel(panels[panelIndex]);
    if (showToggle) {
      // show the one whose value is not null
      const paramValue = tempStore[`${panelIndex}_${0}`];
      if (paramValue !== null) {
        setToggleState('param');
        setTabIndex(0);
      } else {
        setToggleState('body');
        setTabIndex(1);
      }
    } else setToggleState(null);
  }, [panelIndex]);

  useEffect(() => {
    if (toggleState === 'param' || toggleState === 'body') {
      const tabToSetNull = toggleState === 'param' ? 1 : 0;
      const tabToActive = toggleState === 'param' ? 0 : 1;
      updateTempStore(null, panelIndex, tabToSetNull, tempStore);
      setTabIndex(tabToActive);
    }
  }, [toggleState]);

  const isButtonDisabled = (store) => {
    if (typeof store !== 'object' || store === null) {
      return false;
    }
    const checkValue = (value) => {
      if ((typeof value === 'string' && value.trim() === '') || value === '') {
        return false;
      }
      if (Array.isArray(value)) {
        return value.every((item) => checkValue(item));
      }
      if (typeof value === 'object' && value !== null) {
        return Object.values(value).every((item) => checkValue(item));
      }
      return true;
    };
    return Object.values(store).every((value) => checkValue(value));
  };

  // Define an array to group panel configurations
  const panelConfig = [
    { panel: 'headers', currentPanelIndex: 0, currentTabIndex: 0 },
    { panel: 'bodyData', currentPanelIndex: 1, currentTabIndex: 1 },
    { panel: 'formData', currentPanelIndex: 1, currentTabIndex: 0 },
    { panel: 'queryParams', currentPanelIndex: 2, currentTabIndex: 0 },
    { panel: 'empty', currentPanelIndex: 3, currentTabIndex: 0 },
  ];

  const processEntries = (entries, currentPanelIndex, currentTabIndex, currentState) => {
    const formattedEntries = [];
    Object.entries(entries).forEach(([key, value]) => {
      formattedEntries.push({ id: generateUniqueID(), key, value });
    });

    return {
      ...currentState,
      [`${currentPanelIndex}_${currentTabIndex}`]: formattedEntries,
    };
  };

  const handleImportCurl = () => {
    const parsedCurlData = curlInput;
    if (parsedCurlData) {
      const {
        header: headers, data: bodyData, form: formData, params: queryParams,
      } = parsedCurlData;
      let updatedStoreState = {};

      panelConfig.forEach(({ panel, currentPanelIndex, currentTabIndex }) => {
        switch (panel) {
          case 'headers':
            if (headers) {
              updatedStoreState =
              processEntries(headers, currentPanelIndex, currentTabIndex, updatedStoreState);
            } else {
              updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: [] };
            }
            break;

          case 'bodyData':
            if (bodyData) {
              const reshapedBodyData = reshapeObjectDataToArrayState(bodyData, true);
              updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: reshapedBodyData };
            } else {
              updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: null };
            }
            break;

          case 'formData':
            if (formData) {
              const processedFormEntries = formData.map((item) => {
                const [key, valueString] = item.split('=').map((part) => part.trim());
                let value = valueString;
                let type = 'string';

                if (valueString.includes('@')) {
                  value = valueString.split('@')[1].trim();
                  type = 'file';
                }

                return {
                  id: generateUniqueID(),
                  data: {
                    name: key,
                    type,
                    value,
                  },
                };
              });
              updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: processedFormEntries };
            } else {
              updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: null };
            }
            break;

          case 'queryParams':
            if (queryParams) {
              updatedStoreState = processEntries(
                queryParams,
                panelIndex,
                tabIndex,
                updatedStoreState,
              );
            } else {
              updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: [] };
            }
            break;

          case 'empty':
            updatedStoreState = { ...updatedStoreState, [`${currentPanelIndex}_${currentTabIndex}`]: [] };
            break;

          default:
            break;
        }
      });

      setTempStore(updatedStoreState);
    }
  };

  useEffect(() => {
    if (curlInput) {
      handleImportCurl();
    }
  }, [curlInput]);
  return (
    <div className="workflow_configuration_modal__overlay">
      <div className="workflow_configuration_modal__body">
        <Header
          titleText={titleText}
          saveBtnText={saveText}
          onSave={() => {
            saveData(panels, tempStore, selectedNodeId, selectedModuleConfig);
          }}
          onClose={toggleModal}
          isButtonDisabled={!isButtonDisabled(tempStore)}
        />

        <div className="workflow_configuration_modal__content">
          <div className="workflow_configuration_modal__sidebar">
            {
              panels.map(({ texts: panelTexts }, index) => (
                <div key={`${panelTexts.name}`}>
                  <PanelItem
                    text={panelTexts.name}
                    selected={panelIndex === index}
                    onSelect={() => { onPanelSelect(panelIndex, index); }}
                  />
                </div>
              ))
            }
          </div>
          <div className="workflow_configuration_modal__maincontent">
            <div className="workflowInput__topContent">
              <div className="workflowInput__topContent_heading">{panels[panelIndex].texts.title}</div>
              <div className="workflowInput__topContent_subheading">{panels[panelIndex].texts.description}</div>
            </div>
            <div className="workflowInput__bottomContent">
              {
                toggleState !== null ? (
                  <BodyParamToggle
                    value={toggleState}
                    onChange={handleToggle}
                  />
                )
                  : null
              }
              <div className="options-ribbon__configurations">
                {
                  panels[panelIndex].tabs.map(({ title, rootWorkflowKey }, index) => (
                    !isTabHidden(toggleState, rootWorkflowKey) ? (
                      <TabItem
                        key={`${title}`}
                        text={title}
                        selected={tabIndex === index}
                        onSelect={() => { onTabSelect(index); }}
                      />
                    )
                      : null
                  ))
                }
              </div>
              {
                config ? (
                  <EditConfiguration
                    config={panels[panelIndex].tabs[tabIndex]}
                    onChange={(data) => { updateTempStore(data, panelIndex, tabIndex, tempStore); }}
                    data={tempStore[`${panelIndex}_${tabIndex}`]}
                    canDeleteOutputVariable={
                      (variableId) => !checkDeletionDependencies({
                        variableId,
                        nodeId: selectedNodeId,
                        workflow: selectedWorkflow,
                      })
                    }
                    menuOptions={menuOptions}
                  />
                )
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfigurationModal.propTypes = {
  config: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  selectedNodeType: PropTypes.string.isRequired,
  selectedNodeVersion: PropTypes.string.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  versionedModules: PropTypes.object.isRequired,
  orderOfModules: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
  checkDependencies: PropTypes.func.isRequired,
  curlInput: PropTypes.object,
  updateConfigValueInWorkflow: PropTypes.func.isRequired,
};
ConfigurationModal.defaultProps = {
  curlInput: null,
};

export default withDeletionDependencyCheck(ConfigurationModal);
